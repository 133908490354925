<dtm-ui-loader-container [isShown]="data.isProcessing$ | ngrxPush">
    <div class="dialog-header">
        <div mat-dialog-title>
            {{ "dtmSupAddingPermissions.regulationExemptionsDialog.header" | transloco }}
            <div class="operator-info">{{ data.operatorName }}, {{ data.operatorNumber }}</div>
        </div>
        <button type="button" class="button-icon" [matDialogClose]="false">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <form class="exemptions" [formGroup]="form">
            <section>
                <h2 class="exemption-type">{{ "dtmSupAddingPermissions.regulationExemptionsDialog.vlosHeaderLabel" | transloco }}</h2>
                <ng-template
                    [ngTemplateOutlet]="exemptionItemTemplate"
                    [ngTemplateOutletContext]="{
                        formGroup: form.controls.vlos
                    }"
                ></ng-template>
            </section>
            <section>
                <h2 class="exemption-type">{{ "dtmSupAddingPermissions.regulationExemptionsDialog.bvlosHeaderLabel" | transloco }}</h2>
                <ng-template
                    [ngTemplateOutlet]="exemptionItemTemplate"
                    [ngTemplateOutletContext]="{
                        formGroup: form.controls.bvlos
                    }"
                ></ng-template>
            </section>
        </form>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [matDialogClose]="false">
            {{ "dtmSupAddingPermissions.regulationExemptionsDialog.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="save()">
            {{ "dtmSupAddingPermissions.regulationExemptionsDialog.saveButtonLabel" | transloco }}
        </button>
    </div>
</dtm-ui-loader-container>

<ng-template #exemptionItemTemplate let-formGroup="formGroup">
    <div class="exemption-item" [formGroup]="formGroup">
        <div>
            <div class="slider">
                {{ "dtmSupAddingPermissions.regulationExemptionsDialog.heightLabel" | transloco }}
                <mat-slide-toggle formControlName="isHeightEnabled" />
            </div>
            <div class="details" *ngIf="formGroup.controls.isHeightEnabled.value" @slideIn>
                <dtm-ui-expanded-number-input
                    [formControl]="formGroup.controls.height"
                    [minValue]="MIN_HEIGHT_IN_METERS"
                    [maxValue]="MAX_HEIGHT_IN_METERS"
                    [stepValue]="HEIGHT_STEP"
                    [valueSuffix]="'dtmSupAddingPermissions.regulationExemptionsDialog.heightUnitLabel' | transloco"
                >
                    <div class="field-error" *dtmUiFieldHasError="formGroup.controls.height; name: 'min'; error as error">
                        {{ "dtmSupAddingPermissions.regulationExemptionsDialog.minHeightErrorMessage" | transloco : { min: error.min } }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="formGroup.controls.height; name: 'max'; error as error">
                        {{ "dtmSupAddingPermissions.regulationExemptionsDialog.maxHeightErrorMessage" | transloco : { max: error.max } }}
                    </div>
                </dtm-ui-expanded-number-input>
            </div>
        </div>
        <div>
            <div class="slider">
                {{ "dtmSupAddingPermissions.regulationExemptionsDialog.distanceLabel" | transloco }}
                <mat-slide-toggle formControlName="isDistanceEnabled" />
            </div>
            <div class="details" *ngIf="formGroup.controls.isDistanceEnabled.value" @slideIn>
                <dtm-ui-expanded-number-input
                    [formControl]="formGroup.controls.distance"
                    [minValue]="MIN_DISTANCE_IN_KILOMETERS"
                    [maxValue]="MAX_DISTANCE_IN_KILOMETERS"
                    [stepValue]="DISTANCE_STEP"
                    [valueSuffix]="'dtmSupAddingPermissions.regulationExemptionsDialog.distanceUnitLabel' | transloco"
                >
                    <div class="field-error" *dtmUiFieldHasError="formGroup.controls.distance; name: 'min'; error as error">
                        {{ "dtmSupAddingPermissions.regulationExemptionsDialog.minDistanceErrorMessage" | transloco : { min: error.min } }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="formGroup.controls.distance; name: 'max'; error as error">
                        {{ "dtmSupAddingPermissions.regulationExemptionsDialog.maxDistanceErrorMessage" | transloco : { max: error.max } }}
                    </div>
                </dtm-ui-expanded-number-input>
            </div>
        </div>
        <div>
            <div class="slider">
                {{ "dtmSupAddingPermissions.regulationExemptionsDialog.uavTakeOffMassLabel" | transloco }}
                <mat-slide-toggle formControlName="isUavTakeOffMassEnabled" />
            </div>
            <div class="details" *ngIf="formGroup.controls.isUavTakeOffMassEnabled.value" @slideIn>
                <dtm-ui-expanded-number-input
                    [formControl]="formGroup.controls.uavTakeOffMass"
                    [minValue]="MIN_TOM_IN_KILOGRAMS"
                    [maxValue]="MAX_TOM_IN_KILOGRAMS"
                    [stepValue]="TOM_STEP"
                    [valueSuffix]="'dtmSupAddingPermissions.regulationExemptionsDialog.uavTakeOffMassUnitLabel' | transloco"
                >
                    <div class="field-error" *dtmUiFieldHasError="formGroup.controls.uavTakeOffMass; name: 'min'; error as error">
                        {{
                            "dtmSupAddingPermissions.regulationExemptionsDialog.minUavTakeOffMassErrorMessage"
                                | transloco : { min: error.min }
                        }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="formGroup.controls.uavTakeOffMass; name: 'max'; error as error">
                        {{
                            "dtmSupAddingPermissions.regulationExemptionsDialog.maxUavTakeOffMassErrorMessage"
                                | transloco : { max: error.max }
                        }}
                    </div>
                </dtm-ui-expanded-number-input>
            </div>
        </div>
        <div>
            <div class="slider">
                {{ "dtmSupAddingPermissions.regulationExemptionsDialog.arePilotsExemptedFromCompetenciesLabel" | transloco }}
                <mat-slide-toggle formControlName="arePilotsExemptedFromCompetencies" />
            </div>
        </div>
    </div>
</ng-template>
