import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

const MAX_REJECTION_MESSAGE_LENGTH = 200;

@Component({
    templateUrl: "./withdraw-approval-confirmation-dialog.component.html",
    styleUrls: ["./withdraw-approval-confirmation-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WithdrawApprovalConfirmationDialogComponent {
    protected rejectionMessageFormControl = new FormControl("", [Validators.maxLength(MAX_REJECTION_MESSAGE_LENGTH), Validators.required]);
    protected formGroup = new FormGroup({
        rejectionMessage: this.rejectionMessageFormControl,
    });

    protected readonly MAX_REJECTION_MESSAGE_LENGTH = MAX_REJECTION_MESSAGE_LENGTH;

    constructor(private readonly matDialogRef: MatDialogRef<WithdrawApprovalConfirmationDialogComponent>) {}

    public confirm(): void {
        if (this.formGroup.valid) {
            this.matDialogRef.close(this.rejectionMessageFormControl.value);
        }
    }
}
