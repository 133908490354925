import { Injectable } from "@angular/core";
import { FlightPositionUpdaterService } from "@dtm-frontend/shared/map/cesium";
import { GeoZonesActions } from "@dtm-frontend/shared/map/geo-zones";
import {
    EvaluationIssueStatus,
    MissionPlanAnalysisIssueBase,
    MissionPlanAnalysisIssueStatus,
    MissionPlanAnalysisStatus,
    MissionPlanDataAndCapabilities,
    MissionProcessingPhase,
    MissionUtils,
} from "@dtm-frontend/shared/mission";
import { SoundsActions } from "@dtm-frontend/shared/ui";
import {
    DeactivatedSectionsInfo,
    FlightViolationEvent,
    FlightViolationUpdaterService,
    MissionData,
} from "@dtm-frontend/shared/ui/tactical";
import { ArrayUtils } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY, Subject, Subscription, finalize, forkJoin, takeUntil, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { DtmName } from "../../planned-missions/models/mission.models";
import { MissionActions } from "../../planned-missions/state/mission.actions";
import { SupSoundType } from "../../shared";
import { MissionsEvents } from "../../shared/models/shared-supervisor-client.models";
import { SupUserState } from "../../sup-user";
import {
    Checkin,
    CheckinStatus,
    MissionAlert,
    MissionListType,
    MissionStatus,
    OperationalSituationError,
    ProceedingMission,
} from "../models/operational.situation.models";
import { CheckinMessage, OperationalSituationApiService, ViolationMissionMessage } from "../services/operational-situation-api.service";
import { OperationalSituationActions } from "./operational-situation.actions";

const eventsTypeList: MissionsEvents[] = [
    MissionsEvents.MissionAcceptedEvent,
    MissionsEvents.ManualPlanVerificationSubmittedEvent,
    MissionsEvents.MissionActivatedEvent,
];

const refreshDetailsEventsTypeList: MissionsEvents[] = [
    MissionsEvents.MissionCanceledEvent,
    MissionsEvents.MissionActivatedEvent,
    MissionsEvents.MissionCanceledEvent,
    MissionsEvents.MissionEndedByTimeoutEvent,
    MissionsEvents.MissionEndedEvent,
    MissionsEvents.MissionRealizationStartedEvent,
    MissionsEvents.MissionRealizationTimeoutEvent,
];

const checkinListType: MissionsEvents[] = [
    MissionsEvents.CheckinSubmittedEvent,
    MissionsEvents.CheckinRealizationStartedEvent,
    MissionsEvents.CheckinExpiredEvent,
    MissionsEvents.CheckinCompletedEvent,
];

const ISSUES_STATUS_SORTING_ORDER = [
    MissionPlanAnalysisIssueStatus.FatalError,
    MissionPlanAnalysisIssueStatus.Error,
    MissionPlanAnalysisIssueStatus.Hold,
    MissionPlanAnalysisIssueStatus.Warning,
    MissionPlanAnalysisIssueStatus.Info,
    MissionPlanAnalysisIssueStatus.Success,
    EvaluationIssueStatus.Rejected,
    EvaluationIssueStatus.Suppressed,
    EvaluationIssueStatus.Open,
] as const;

const mapEventTypeToCheckinStatus = new Map<MissionsEvents, CheckinStatus>([
    [MissionsEvents.CheckinSubmittedEvent, CheckinStatus.Submitted],
    [MissionsEvents.CheckinRealizationStartedEvent, CheckinStatus.InRealization],
    [MissionsEvents.CheckinExpiredEvent, CheckinStatus.Expired],
    [MissionsEvents.CheckinCompletedEvent, CheckinStatus.Completed],
]);

const ACTIVE_CHECKIN_STATUSES = [CheckinStatus.InRealization, CheckinStatus.Submitted, CheckinStatus.Expired];

interface OperationalSituationStateModel {
    isProcessing: boolean;
    alertList: MissionAlert[] | undefined;
    alertListError: OperationalSituationError | undefined;
    missionListError: OperationalSituationError | undefined;
    checkinListError: OperationalSituationError | undefined;
    proceedingMissions: ProceedingMission[] | undefined;
    checkinList: Checkin[] | undefined;
    incomingMissions: ProceedingMission[] | undefined;
    finishedMissions: ProceedingMission[] | undefined;
    rejectMissionError: OperationalSituationError | undefined;
    overrideMissionError: OperationalSituationError | undefined;
    missionPlanDataError: OperationalSituationError | undefined;
    planAnalysisError: OperationalSituationError | undefined;
    airspaceError: OperationalSituationError | undefined;
    dismissAlertError: OperationalSituationError | undefined;
    selectedMissionData: MissionData | undefined;
    currentMissionsData: MissionData[];
    deactivatedSectionsInfo: DeactivatedSectionsInfo[] | undefined;
    selectedOperationId: string | undefined;
    selectedCheckin: Checkin | undefined;
    missionPlanAnalysisStatus: MissionPlanAnalysisStatus | undefined;
    currentPlanDataAndCapabilities: MissionPlanDataAndCapabilities | undefined;
    missionDetailsError: OperationalSituationError | undefined;
    selectedDtmName: DtmName | undefined;
}

const defaultState: OperationalSituationStateModel = {
    isProcessing: false,
    alertList: undefined,
    alertListError: undefined,
    missionListError: undefined,
    checkinListError: undefined,
    proceedingMissions: undefined,
    checkinList: undefined,
    incomingMissions: undefined,
    finishedMissions: undefined,
    rejectMissionError: undefined,
    overrideMissionError: undefined,
    dismissAlertError: undefined,
    selectedMissionData: undefined,
    deactivatedSectionsInfo: undefined,
    currentMissionsData: [],
    selectedOperationId: undefined,
    selectedCheckin: undefined,
    missionPlanAnalysisStatus: undefined,
    airspaceError: undefined,
    currentPlanDataAndCapabilities: undefined,
    missionPlanDataError: undefined,
    planAnalysisError: undefined,
    missionDetailsError: undefined,
    selectedDtmName: undefined,
};

@State<OperationalSituationStateModel>({
    name: "operationalSituation",
    defaults: defaultState,
})
@Injectable()
export class OperationalSituationState {
    private stopTacticalMissionsWatch$: Subject<void> | undefined;
    private stopFlightPositionUpdates$: Subject<void> | undefined;
    private sectionDeactivationEventsWatchSubscription: Subscription | undefined;

    constructor(
        private readonly operationalSituationApi: OperationalSituationApiService,
        private readonly flightPositionUpdater: FlightPositionUpdaterService,
        private readonly flightViolationUpdater: FlightViolationUpdaterService,
        private readonly store: Store
    ) {}

    @Selector()
    public static isProcessing(state: OperationalSituationStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static alertList(state: OperationalSituationStateModel): MissionAlert[] | undefined {
        return state.alertList;
    }

    @Selector()
    public static alertListError(state: OperationalSituationStateModel): OperationalSituationError | undefined {
        return state.alertListError;
    }

    @Selector()
    public static missionListError(state: OperationalSituationStateModel): OperationalSituationError | undefined {
        return state.missionListError;
    }

    @Selector()
    public static proceedingMissions(state: OperationalSituationStateModel): ProceedingMission[] | undefined {
        return state.proceedingMissions;
    }

    @Selector()
    public static checkinList(state: OperationalSituationStateModel): Checkin[] | undefined {
        return state.checkinList?.filter((checkin) => ACTIVE_CHECKIN_STATUSES.some((status) => status === checkin.status));
    }

    @Selector()
    public static finishedCheckinList(state: OperationalSituationStateModel): Checkin[] | undefined {
        return state.checkinList?.filter((checkin) => checkin.status === CheckinStatus.Completed);
    }

    @Selector()
    public static finishedMissions(state: OperationalSituationStateModel): ProceedingMission[] | undefined {
        return state.finishedMissions;
    }

    @Selector()
    public static incomingMissions(state: OperationalSituationStateModel): ProceedingMission[] | undefined {
        return state.incomingMissions;
    }

    @Selector()
    public static rejectMissionError(state: OperationalSituationStateModel): OperationalSituationError | undefined {
        return state.rejectMissionError;
    }

    @Selector()
    public static overrideMissionError(state: OperationalSituationStateModel): OperationalSituationError | undefined {
        return state.overrideMissionError;
    }

    @Selector()
    public static dismissAlertError(state: OperationalSituationStateModel): OperationalSituationError | undefined {
        return state.dismissAlertError;
    }

    @Selector()
    public static missionDetailsError(state: OperationalSituationStateModel): OperationalSituationError | undefined {
        return state.missionDetailsError;
    }

    @Selector()
    public static selectedMissionData(state: OperationalSituationStateModel): MissionData | undefined {
        return state.selectedMissionData;
    }

    @Selector()
    public static deactivatedSectionsInfo(state: OperationalSituationStateModel): DeactivatedSectionsInfo[] | undefined {
        return state.deactivatedSectionsInfo;
    }

    @Selector()
    public static currentMissionsData(state: OperationalSituationStateModel): MissionData[] {
        return state.currentMissionsData;
    }

    @Selector()
    public static selectedOperationId(state: OperationalSituationStateModel): string | undefined {
        return state.selectedOperationId;
    }

    @Selector()
    public static selectedCheckin(state: OperationalSituationStateModel): Checkin | undefined {
        return state.selectedCheckin;
    }

    @Selector()
    public static currentMissionPlanData(state: OperationalSituationStateModel): MissionPlanDataAndCapabilities | undefined {
        return state.currentPlanDataAndCapabilities;
    }

    @Selector()
    public static currentPlanAnalysisStatus(state: OperationalSituationStateModel): MissionPlanAnalysisStatus | undefined {
        return state.missionPlanAnalysisStatus;
    }

    @Selector()
    public static missionAnalysisDataError(state: OperationalSituationStateModel): boolean {
        return !!state.planAnalysisError || !!state.airspaceError;
    }

    @Selector()
    public static selectedDtmName(state: OperationalSituationStateModel): DtmName | undefined {
        return state.selectedDtmName;
    }

    @Action(OperationalSituationActions.TacticalMissionsWatch)
    public tacticalMissionWatch(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.TacticalMissionsWatch
    ) {
        this.stopTacticalMissionsWatch$ = new Subject<void>();
        const authorityUnitId = this.getAuthorityUnitId();

        return this.operationalSituationApi.startPlannedMissionsWatch(action.dtmName, authorityUnitId).pipe(
            tap((message) => {
                const {
                    headers: { eventType, missionId },
                } = message;

                if (this.shouldRunAction(refreshDetailsEventsTypeList, eventType)) {
                    context.dispatch([new OperationalSituationActions.RefreshCurrentMissionDetails(missionId)]);
                }

                if (this.shouldRunAction(eventsTypeList, eventType)) {
                    context.dispatch(new OperationalSituationActions.GetMission(missionId, eventType));

                    return;
                }

                if (this.shouldRunAction(checkinListType, eventType)) {
                    const { body } = message as CheckinMessage;
                    context.dispatch(new OperationalSituationActions.UpdateCheckinList(body, eventType));

                    return;
                }

                switch (eventType) {
                    case MissionsEvents.MissionEndedEvent:
                    case MissionsEvents.MissionEndedByTimeoutEvent: {
                        context.dispatch(new OperationalSituationActions.MoveMissionToFinished(missionId, MissionListType.Proceeding));
                        break;
                    }
                    case MissionsEvents.MissionRealizationTimeoutEvent: {
                        context.dispatch(
                            new OperationalSituationActions.MoveMissionToFinished(
                                missionId,
                                MissionListType.Incoming,
                                MissionProcessingPhase.MissionAbandoned
                            )
                        );
                        break;
                    }
                    case MissionsEvents.MissionCanceledEvent: {
                        context.dispatch(new OperationalSituationActions.MoveMissionToFinished(missionId, MissionListType.Incoming));
                        break;
                    }
                    case MissionsEvents.MissionRealizationStartedEvent: {
                        context.dispatch(new OperationalSituationActions.StartMissionRealization(missionId));
                        break;
                    }
                    case MissionsEvents.FlightViolationOccurredEvent: {
                        context.dispatch([
                            new OperationalSituationActions.GetMissionAlerts(action.dtmName),
                            new OperationalSituationActions.MarkViolation(missionId),
                        ]);

                        this.flightViolationUpdater.addFlightViolationUpdate({
                            type: FlightViolationEvent.FlightViolationOccurredEvent,
                            payload: (message as ViolationMissionMessage).body,
                        });
                        break;
                    }
                    case MissionsEvents.FlightViolationCanceledEvent: {
                        context.dispatch(new OperationalSituationActions.GetMissionAlerts(action.dtmName));

                        this.flightViolationUpdater.addFlightViolationUpdate({
                            type: FlightViolationEvent.FlightViolationCanceledEvent,
                            payload: (message as ViolationMissionMessage).body,
                        });
                        break;
                    }
                    default:
                        return;
                }
            }),
            takeUntil(this.stopTacticalMissionsWatch$)
        );
    }

    @Action(OperationalSituationActions.UpdateCheckinList)
    public updateCheckinList(context: StateContext<OperationalSituationStateModel>, action: OperationalSituationActions.UpdateCheckinList) {
        if (action.checkin.missionId) {
            return;
        }

        switch (action.eventType) {
            case MissionsEvents.CheckinSubmittedEvent: {
                context.dispatch(new OperationalSituationActions.PatchSingleCheckin(action.checkin));

                break;
            }
            case MissionsEvents.CheckinRealizationStartedEvent:
            case MissionsEvents.CheckinCompletedEvent:
            case MissionsEvents.CheckinExpiredEvent: {
                context.dispatch(
                    new OperationalSituationActions.ChangeCheckinStatus(
                        action.checkin.id,
                        mapEventTypeToCheckinStatus.get(action.eventType) as CheckinStatus
                    )
                );

                break;
            }

            default:
                return;
        }
    }

    @Action(OperationalSituationActions.PatchSingleCheckin)
    public patchSingleCheckin(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.PatchSingleCheckin
    ) {
        const checkinState = context.getState().checkinList;

        context.patchState({
            checkinList: [...(checkinState ?? []), action.checkin],
        });
    }

    @Action(OperationalSituationActions.ChangeCheckinStatus)
    public markCheckinExpired(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.ChangeCheckinStatus
    ) {
        const checkinState = context.getState().checkinList;

        if (!checkinState) {
            return;
        }

        if (action.checkinStatus === CheckinStatus.InRealization) {
            context.dispatch(new SoundsActions.PlaySound(SupSoundType.TacticalNotification));
        }

        context.patchState({
            checkinList: checkinState.map((checkin) =>
                checkin.id === action.checkinId ? { ...checkin, status: action.checkinStatus } : checkin
            ),
        });
    }

    @Action(OperationalSituationActions.MoveCompletedCheckin)
    public moveCompletedEvent(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.MoveCompletedCheckin
    ) {
        const checkinState = context.getState().checkinList;

        if (!checkinState) {
            return;
        }

        context.patchState({
            checkinList: checkinState.filter((checkin) => checkin.id !== action.checkinId),
        });
    }

    @Action(OperationalSituationActions.UpdateSelectedCheckin)
    public updateSelectedOperationId(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.UpdateSelectedCheckin
    ) {
        context.patchState({
            selectedOperationId: action.checkin.id,
            selectedCheckin: action.checkin,
            selectedMissionData: undefined,
        });
    }

    @Action(OperationalSituationActions.StopTacticalMissionsWatch)
    public stopTacticalMissionsWatch(context: StateContext<OperationalSituationStateModel>) {
        this.stopTacticalMissionsWatch$?.next();
        this.stopTacticalMissionsWatch$?.complete();

        context.patchState({
            proceedingMissions: [],
            incomingMissions: [],
            finishedMissions: [],
        });
    }

    @Action(OperationalSituationActions.StartMissionRealization)
    public startMissionRealization(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.StartMissionRealization
    ) {
        const proceedingMissions = context.getState().proceedingMissions;

        if (!proceedingMissions) {
            return;
        }

        context.dispatch(new SoundsActions.PlaySound(SupSoundType.TacticalNotification));

        context.patchState({
            proceedingMissions: proceedingMissions.map((mission) =>
                mission.missionId === action.missionId ? { ...mission, missionStatus: MissionStatus.Started } : mission
            ),
        });
    }

    @Action(OperationalSituationActions.GetMission)
    public getMission(context: StateContext<OperationalSituationStateModel>, action: OperationalSituationActions.GetMission) {
        return this.operationalSituationApi.getMission(action.missionId).pipe(
            tap((mission) => {
                switch (action.eventType) {
                    case MissionsEvents.MissionAcceptedEvent:
                    case MissionsEvents.ManualPlanVerificationSubmittedEvent: {
                        context.dispatch(new OperationalSituationActions.UpdateIncomingMissions(mission));
                        break;
                    }
                    case MissionsEvents.MissionRealizationStartedEvent:
                        context.dispatch(new OperationalSituationActions.MoveMissionToRealization(mission));
                        break;
                    case MissionsEvents.MissionActivatedEvent: {
                        context.dispatch(new OperationalSituationActions.MoveMissionToRealization(mission));
                        break;
                    }
                    default:
                        return;
                }
            })
        );
    }

    @Action(OperationalSituationActions.UpdateIncomingMissions)
    public updateIncomingMissions(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.UpdateIncomingMissions
    ) {
        const incomingMissions = context.getState().incomingMissions;

        if (!incomingMissions) {
            return;
        }

        context.patchState({
            incomingMissions: this.sortDescendingByMissionTime([action.mission, ...incomingMissions]),
        });
    }

    @Action(OperationalSituationActions.MoveMissionToFinished)
    public moveMissionToFinished(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.MoveMissionToFinished
    ) {
        if (action.fromListType === MissionListType.Proceeding) {
            const proceedingMissions = context.getState().proceedingMissions;

            if (!proceedingMissions) {
                return;
            }

            const updatedMission = this.updateMissionStatus(proceedingMissions, action.missionId, MissionStatus.Finished);

            context.patchState({
                proceedingMissions: [...this.dropMissionById(proceedingMissions, action.missionId)],
                finishedMissions: this.sortDescendingByMissionTime([updatedMission, ...(context.getState().finishedMissions ?? [])]),
            });
        }

        if (action.fromListType === MissionListType.Incoming) {
            const incomingMissions = context.getState().incomingMissions;

            if (!incomingMissions) {
                return;
            }

            const updatedMission = this.updateMissionStatus(incomingMissions, action.missionId, MissionStatus.Finished, action.phase);

            context.patchState({
                incomingMissions: [...this.dropMissionById(incomingMissions, action.missionId)],
                finishedMissions: this.sortDescendingByMissionTime([updatedMission, ...(context.getState().finishedMissions ?? [])]),
            });
        }
    }

    @Action(OperationalSituationActions.MoveMissionToRealization)
    public moveToRealization(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.MoveMissionToRealization
    ) {
        const proceedingMissions = context.getState().proceedingMissions;
        const incomingMissions = context.getState().incomingMissions;

        const updatedMission: ProceedingMission = {
            ...action.mission,
            missionStatus: MissionStatus.Activated,
        };

        if (!proceedingMissions || !incomingMissions) {
            return;
        }

        context.patchState({
            incomingMissions: [...this.dropMissionById(incomingMissions, action.mission.missionId)],
            proceedingMissions: this.sortDescendingByMissionTime([updatedMission, ...proceedingMissions]),
        });
    }

    @Action(OperationalSituationActions.GetMissionAlerts)
    public getMissionAlerts(context: StateContext<OperationalSituationStateModel>, action: OperationalSituationActions.GetMissionAlerts) {
        context.patchState({ alertList: undefined });

        const authorityUnitId = this.getAuthorityUnitId();

        return this.operationalSituationApi.getMissionAlertList(action.dtmName, authorityUnitId).pipe(
            tap((alertList) => context.patchState({ alertList })),
            catchError((alertListError) => {
                context.patchState({ alertListError });

                return EMPTY;
            })
        );
    }

    @Action(OperationalSituationActions.GetMissions)
    public getMissions(context: StateContext<OperationalSituationStateModel>, { dtmName }: OperationalSituationActions.GetMissions) {
        context.patchState({ isProcessing: true });

        const authorityUnitId = this.getAuthorityUnitId();

        return this.operationalSituationApi.getMissions(dtmName, authorityUnitId).pipe(
            tap((missions) => {
                const proceedingMissions = this.filterByStatus(missions, [MissionStatus.Activated, MissionStatus.Started]);
                context.patchState({
                    proceedingMissions: this.sortDescendingByMissionTime(proceedingMissions),
                    incomingMissions: this.sortDescendingByMissionTime(this.filterByStatus(missions, [MissionStatus.Accepted])),
                    finishedMissions: this.sortDescendingByMissionTime(
                        this.filterByStatus(missions, [MissionStatus.Finished, MissionStatus.Canceled, MissionStatus.Rejected])
                    ),
                });

                context.dispatch(
                    new OperationalSituationActions.GetCurrentMissionsDetails(proceedingMissions.map(({ missionId }) => missionId))
                );
            }),
            catchError((missionListError) => {
                context.patchState({ missionListError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperationalSituationActions.CloseMissionAlert)
    public closeMissionAlert(
        context: StateContext<OperationalSituationStateModel>,
        { payloadData }: OperationalSituationActions.CloseMissionAlert
    ) {
        context.patchState({ dismissAlertError: undefined });

        return this.operationalSituationApi.dismissAlert(payloadData.missionId, this.getAuthorityUnitId()).pipe(
            tap(() => {
                const currentAlertList = context.getState().alertList;

                if (!currentAlertList) {
                    return;
                }

                context.patchState({
                    alertList: currentAlertList.filter((_, index) => index !== payloadData.alertIndex),
                });
            }),
            catchError((dismissAlertError) => {
                context.patchState({ dismissAlertError });

                return EMPTY;
            })
        );
    }

    @Action(OperationalSituationActions.RejectMission)
    public rejectMission(context: StateContext<OperationalSituationStateModel>, action: OperationalSituationActions.RejectMission) {
        context.patchState({ isProcessing: true, rejectMissionError: undefined });

        return this.operationalSituationApi.rejectMission(action.missionId, action.information, this.getAuthorityUnitId()).pipe(
            tap(() => {
                switch (action.listType) {
                    case MissionListType.Proceeding:
                        context.dispatch(new OperationalSituationActions.RejectProceedingMission(action.missionId));
                        break;
                    case MissionListType.Incoming:
                        context.dispatch(new OperationalSituationActions.RejectIncomingMission(action.missionId));
                        break;
                    default:
                        return;
                }
            }),
            catchError((rejectMissionError) => {
                context.patchState({ rejectMissionError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperationalSituationActions.RejectIncomingMission)
    public rejectIncomingMission(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.RejectIncomingMission
    ) {
        const incomingMissions = context.getState().incomingMissions;

        if (!incomingMissions) {
            return;
        }

        const updatedMission = this.updateMissionStatus(
            incomingMissions,
            action.missionId,
            MissionStatus.Rejected,
            MissionProcessingPhase.Rejected
        );

        context.patchState({
            incomingMissions: [...this.dropMissionById(incomingMissions, action.missionId)],
            finishedMissions: this.sortDescendingByMissionTime([updatedMission, ...(context.getState().finishedMissions ?? [])]),
        });
    }

    @Action(OperationalSituationActions.RejectProceedingMission)
    public rejectProceedingMission(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.RejectProceedingMission
    ) {
        const proceedingMissions = context.getState().proceedingMissions;

        if (!proceedingMissions) {
            return;
        }

        const updatedMission = this.updateMissionStatus(proceedingMissions, action.missionId, MissionStatus.Rejected);

        context.patchState({
            proceedingMissions: [...this.dropMissionById(proceedingMissions, action.missionId)],
            finishedMissions: this.sortDescendingByMissionTime([updatedMission, ...(context.getState().finishedMissions ?? [])]),
        });
    }

    @Action(OperationalSituationActions.StartFlightPositionsUpdateWatch)
    public startFlightPositionsUpdatesWatch(
        context: StateContext<OperationalSituationStateModel>,
        { bbox }: OperationalSituationActions.StartFlightPositionsUpdateWatch
    ) {
        this.stopFlightPositionUpdates$?.next();
        this.stopFlightPositionUpdates$?.complete();
        this.stopFlightPositionUpdates$ = new Subject<void>();

        return this.operationalSituationApi.startFlightPositionUpdatesWatch(bbox).pipe(
            takeUntil(this.stopFlightPositionUpdates$),
            tap((position) => this.flightPositionUpdater.addFlightPositionUpdate(position))
        );
    }

    @Action(OperationalSituationActions.StopFlightPositionUpdatesWatch)
    public stopFlightPositionUpdatesWatch() {
        this.stopFlightPositionUpdates$?.next();
        this.stopFlightPositionUpdates$?.complete();
    }

    @Action(OperationalSituationActions.OverrideMissionTime)
    public overrideMissionTime(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.OverrideMissionTime
    ) {
        context.patchState({ isProcessing: true });

        return this.operationalSituationApi.overrideMissionTime(action.missionId, action.data, this.getAuthorityUnitId()).pipe(
            tap(() => {
                context.dispatch([
                    new OperationalSituationActions.GetMissions(action.dtmName),
                    new OperationalSituationActions.RefreshCurrentMissionDetails(action.missionId),
                ]);
            }),
            catchError((overrideMissionError) => {
                context.patchState({ overrideMissionError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperationalSituationActions.MarkViolation)
    public markViolation(context: StateContext<OperationalSituationStateModel>, action: OperationalSituationActions.MarkViolation) {
        const proceedingMissions = context.getState().proceedingMissions;

        if (!proceedingMissions) {
            return;
        }

        context.patchState({
            proceedingMissions: proceedingMissions.map((mission) =>
                mission.missionId === action.missionId ? { ...mission, isViolated: true } : mission
            ),
        });
    }

    @Action(OperationalSituationActions.GetSelectedMissionDetails)
    public getMissionDetails(
        context: StateContext<OperationalSituationStateModel>,
        { missionId, planId }: OperationalSituationActions.GetSelectedMissionDetails
    ) {
        context.patchState({
            missionPlanAnalysisStatus: undefined,
            currentPlanDataAndCapabilities: undefined,
            selectedMissionData: undefined,
            missionDetailsError: undefined,
        });

        return this.operationalSituationApi.getMissionDetails(missionId).pipe(
            tap((mission) => {
                context.patchState({
                    selectedMissionData: mission,
                    selectedOperationId: mission.missionId,
                    selectedCheckin: undefined,
                });

                context.dispatch(new OperationalSituationActions.GetMissionPlanAnalysis(planId));
            }),
            catchError((missionDetailsError) => {
                context.patchState({
                    missionDetailsError,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperationalSituationActions.GetCurrentMissionsDetails)
    public getCurrentMissionsDetails(
        context: StateContext<OperationalSituationStateModel>,
        { missionIds }: OperationalSituationActions.GetCurrentMissionsDetails
    ) {
        return forkJoin(missionIds.map((missionId) => this.operationalSituationApi.getMissionDetails(missionId))).pipe(
            tap((results) => {
                context.patchState({
                    currentMissionsData: results,
                });
            })
        );
    }

    @Action(OperationalSituationActions.RefreshCurrentMissionDetails)
    public refreshCurrentMissionDetails(
        context: StateContext<OperationalSituationStateModel>,
        { missionId }: OperationalSituationActions.RefreshCurrentMissionDetails
    ) {
        return this.operationalSituationApi.getMissionDetails(missionId).pipe(
            tap((results) => {
                const itemIndex = context.getState().currentMissionsData.findIndex(({ missionId: id }) => results.missionId === id);
                const currentMissionsData = [...context.getState().currentMissionsData];
                const isActive = [MissionStatus.Activated, MissionStatus.Started].includes(results.status);
                let selectedMissionData = context.getState().selectedMissionData;

                if (itemIndex > -1 && isActive) {
                    currentMissionsData[itemIndex] = results;
                } else if (itemIndex === -1 && isActive) {
                    currentMissionsData.push(results);
                } else {
                    currentMissionsData.splice(itemIndex, 1);
                }

                if (selectedMissionData?.missionId === missionId) {
                    selectedMissionData = results;
                }
                context.patchState({
                    currentMissionsData,
                    selectedMissionData,
                });
            })
        );
    }

    @Action(OperationalSituationActions.ClearMapEntities)
    public clearMissionDetails(context: StateContext<OperationalSituationStateModel>) {
        context.patchState({
            selectedMissionData: undefined,
            selectedCheckin: undefined,
            selectedOperationId: undefined,
            currentMissionsData: [],
        });
    }

    @Action(OperationalSituationActions.StartSectionDeactivationEventWatch)
    public startTrackerUpdatesWatch(
        context: StateContext<OperationalSituationStateModel>,
        { dtmName }: OperationalSituationActions.StartSectionDeactivationEventWatch
    ) {
        this.sectionDeactivationEventsWatchSubscription?.unsubscribe();
        context.patchState({ deactivatedSectionsInfo: undefined });

        this.sectionDeactivationEventsWatchSubscription = this.operationalSituationApi
            .startSectionDeactivatedEventWatch(dtmName)
            .subscribe((response) => {
                if (!response) {
                    return;
                }
                const { deactivatedSectionsInfo } = context.getState();

                const deactivatedSection: DeactivatedSectionsInfo = {
                    trackerId: response.trackerIdentifier,
                    sectionIndex: response.sectionIndex,
                };

                const deactivatedSectionsInfoUpdated = deactivatedSectionsInfo
                    ? ArrayUtils.unique([...deactivatedSectionsInfo, deactivatedSection], (item) => item.trackerId)
                    : [deactivatedSection];

                context.patchState({ deactivatedSectionsInfo: deactivatedSectionsInfoUpdated });
            });
    }

    @Action(OperationalSituationActions.StopSectionDeactivationEventWatch)
    public stopSectionDeactivationEventWatch(context: StateContext<OperationalSituationStateModel>) {
        this.sectionDeactivationEventsWatchSubscription?.unsubscribe();
        context.patchState({ deactivatedSectionsInfo: undefined });
    }

    @Action(OperationalSituationActions.GetCheckins)
    public getCheckins(context: StateContext<OperationalSituationStateModel>, action: OperationalSituationActions.GetCheckins) {
        context.patchState({ isProcessing: true, checkinList: [] });

        const authorityUnitId = this.getAuthorityUnitId();

        return this.operationalSituationApi.getCheckins(action.dtmName, authorityUnitId).pipe(
            tap((checkinList) => {
                context.patchState({
                    checkinList,
                });
            }),
            catchError((checkinListError) => {
                context.patchState({ checkinListError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperationalSituationActions.GetMissionPlanData)
    public getPlanData(context: StateContext<OperationalSituationStateModel>, { planId }: MissionActions.GetMissionPlanData) {
        context.patchState({ isProcessing: true, missionPlanDataError: undefined, currentPlanDataAndCapabilities: undefined });
        const authorityUnit = this.store.selectSnapshot(SupUserState.selectedWorkspace)?.authorityUnit;

        return this.operationalSituationApi.getMissionPlanData(planId, authorityUnit).pipe(
            tap((currentPlanDataAndCapabilities) => {
                context.patchState({
                    currentPlanDataAndCapabilities,
                });
            }),
            catchError((missionPlanDataError) => {
                context.patchState({
                    missionPlanDataError,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }

    @Action(OperationalSituationActions.UpdateSelectedDtmName)
    public updateSelectedDtmName(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.UpdateSelectedDtmName
    ) {
        context.patchState({
            selectedDtmName: action.dtmName,
        });
    }

    @Action(OperationalSituationActions.SearchAirspaceElements)
    public searchAirspaceElements(
        context: StateContext<OperationalSituationStateModel>,
        { options }: OperationalSituationActions.SearchAirspaceElements
    ) {
        context.patchState({ isProcessing: true });

        return this.operationalSituationApi.searchAirspaceElements(options).pipe(
            tap((elements) => {
                this.store.dispatch(new GeoZonesActions.SetCustomElements(elements));
            }),
            catchError((error) => {
                context.patchState({
                    airspaceError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperationalSituationActions.UpdateMissionPlanAnalysisStatus)
    public updateMissionPlanAnalysisStatus(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.UpdateMissionPlanAnalysisStatus
    ) {
        const { missionPlanAnalysisStatus, selectedMissionData } = context.getState();

        if (
            missionPlanAnalysisStatus &&
            missionPlanAnalysisStatus.systemVerificationId === action.status.systemVerificationId &&
            missionPlanAnalysisStatus.version >= action.status.version
        ) {
            return;
        }

        this.sortAnalysisIssues(action.status.airspace?.issues);
        this.sortAnalysisIssues(action.status.traffic?.issues);
        this.sortAnalysisIssues(action.status.caaPermit?.issues);
        this.sortAnalysisIssues(action.status.evaluation?.issues);

        context.dispatch([OperationalSituationActions.ClearAirspaceElements]);

        if (
            selectedMissionData?.route &&
            ((action.status.airspace.zoneIssues && Object.keys(action.status.airspace.zoneIssues).length) ||
                (action.status.evaluation.zoneIssues && Object.keys(action.status.evaluation.zoneIssues).length))
        ) {
            const waypoints = MissionUtils.convertRouteToWaypoints(selectedMissionData.route);
            const timeRange = MissionUtils.getTimeRangeFromWaypointsWithSection(waypoints);

            context.dispatch(
                new OperationalSituationActions.SearchAirspaceElements({
                    designators: [
                        ...Object.keys(action.status.airspace.zoneIssues ?? {}),
                        ...Object.keys(action.status.evaluation.zoneIssues ?? {}),
                    ],
                    scope: {
                        startTime: timeRange.min,
                        endTime: timeRange.max,
                    },
                    includeInformation: true,
                })
            );
        }

        context.patchState({
            missionPlanAnalysisStatus: action.status,
        });
    }

    @Action(OperationalSituationActions.GetMissionPlanAnalysis)
    public getMissionPlanAnalysis(
        context: StateContext<OperationalSituationStateModel>,
        action: OperationalSituationActions.GetMissionPlanAnalysis
    ) {
        context.patchState({ isProcessing: true });

        return this.operationalSituationApi.getCurrentMissionPlanVerification(action.planId).pipe(
            tap((firstStatus) => {
                context.patchState({
                    isProcessing: false,
                });
                context.dispatch(new OperationalSituationActions.UpdateMissionPlanAnalysisStatus(firstStatus));
            }),
            catchError((error) => {
                context.patchState({
                    planAnalysisError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperationalSituationActions.ClearAirspaceElements)
    public clearAirspaceElements() {
        this.store.dispatch(new GeoZonesActions.SetCustomElements());
    }

    @Action(OperationalSituationActions.ClearMissionAnalysisAndCapabilities)
    public clearMissionAnalysis(context: StateContext<OperationalSituationStateModel>) {
        context.patchState({ missionPlanAnalysisStatus: undefined, currentPlanDataAndCapabilities: undefined });
    }

    private sortAnalysisIssues(issues?: (MissionPlanAnalysisIssueBase & { status: string })[]) {
        issues?.sort((left, right) => {
            if (left.codename !== right.codename) {
                return left.codename.localeCompare(right.codename);
            }

            const leftWeight = ISSUES_STATUS_SORTING_ORDER.findIndex((item) => item === left.status);
            const rightWeight = ISSUES_STATUS_SORTING_ORDER.findIndex((item) => item === right.status);

            if (leftWeight !== rightWeight) {
                return leftWeight - rightWeight;
            }

            return left.translationId.localeCompare(right.translationId);
        });
    }

    private dropMissionById(missionList: ProceedingMission[], currentMissionId: string): ProceedingMission[] {
        return missionList.filter((mission) => mission.missionId !== currentMissionId);
    }

    private shouldRunAction(eventTypeList: MissionsEvents[], event: MissionsEvents): boolean {
        return eventTypeList.includes(event);
    }

    private filterByStatus(missionList: ProceedingMission[], status: MissionStatus[]): ProceedingMission[] {
        return missionList.filter((mission) => status.includes(mission.missionStatus));
    }

    private updateMissionStatus(
        missions: ProceedingMission[],
        currentMissionId: string,
        status: MissionStatus,
        phase?: MissionProcessingPhase
    ): ProceedingMission {
        return missions
            .filter((mission) => mission.missionId === currentMissionId)
            .map((mission) => ({
                ...mission,
                missionStatus: status,
                phase: phase ?? mission.phase,
            }))[0];
    }

    private sortDescendingByMissionTime(missions: ProceedingMission[]): ProceedingMission[] {
        return missions.sort((left, right) => {
            if (left.flightStartAtMin && right.flightStartAtMin) {
                return left.flightStartAtMin.getTime() - right.flightStartAtMin.getTime();
            }

            return 0;
        });
    }

    private getAuthorityUnitId() {
        return this.store.selectSnapshot(SupUserState.selectedWorkspace)?.authorityUnit.id;
    }
}
