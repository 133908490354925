import { HttpErrorResponse, HttpParams, HttpStatusCode } from "@angular/common/http";
import { MissionProcessingPhase, MissionType } from "@dtm-frontend/shared/mission";
import { GeoJSON, Page, PageResponseBody, PhoneNumber } from "@dtm-frontend/shared/ui";
import { FlightViolationUpdate } from "@dtm-frontend/shared/ui/tactical";
import { DateUtils } from "@dtm-frontend/shared/utils";
import { WebsocketMessage } from "@dtm-frontend/shared/websocket";
import { Feature, Feature as GeoJSONFeature } from "@turf/helpers";
import { DtmName } from "../../planned-missions/models/mission.models";
import {
    GeographicalZone,
    InfoZone,
    MissionAlert,
    MissionStatus,
    OperationType,
    ProceedingMission,
    TemporaryZoneError,
    TemporaryZoneErrorType,
    ViolationType,
} from "../models/operational.situation.models";
import {
    AltitudeType,
    BasicTemporaryZone,
    DailyActivationData,
    TemporaryZone,
    TemporaryZoneFormData,
    WeekDay,
    WeeklyActivation,
    WeeklyActivationData,
    ZoneFiltersData,
    ZoneObjectType,
    ZoneSortCriteria,
} from "../models/temporary-zones.models";
import { MissionMessageHeaders, MissionMessageHeadersBody } from "./operational-situation-api.service";

const GROUP_ALERTS_KEY = "missionId";
const DEFAULT_PRIORITY = "9";
const ZONE_LANGUAGE_TAG = "pl-PL";

export const DEFAULT_ARCHIVE_ZONES_LIST_SIZE = 5;

export type BasicTemporaryZoneResponseBody = PageResponseBody<BasicTemporaryZoneResponseBodyContent>;

/* eslint-disable no-magic-numbers*/
export const weekDayToWeekDayNameMapper = new Map([
    [1, WeekDay.Monday],
    [2, WeekDay.Tuesday],
    [3, WeekDay.Wednesday],
    [4, WeekDay.Thursday],
    [5, WeekDay.Friday],
    [6, WeekDay.Saturday],
    [0, WeekDay.Sunday],
]);
/* eslint-enable no-magic-numbers*/

const weekDaySortList = Object.values(WeekDay);

export interface ZoneRequestParams {
    listRequest: {
        dtmNames?: DtmName[];
        fromTime?: string;
        toTime?: string;
        name?: string;
        localInformationTypes?: InfoZone;
        localInformationObjectTypes?: ZoneObjectType[];
        types?: GeographicalZone[];
        onlyArchive?: boolean;
        onlyActive?: boolean;
        authorityUnitIds?: string[];
    };
    page: {
        size: number;
        page: number;
        sort?: ZoneSortCriteria[];
    };
}

interface ZoneFiltersParams {
    fromTime?: string;
    toTime?: string;
    name?: string;
    localInformationTypes?: InfoZone;
    localInformationObjectTypes?: ZoneObjectType[];
    types?: GeographicalZone[];
    authorityUnitIds?: string[];
}

export const createTemporaryZoneParams = (dtmName?: DtmName, zonesFiltersData?: ZoneFiltersData, authorityUnitId?: string): HttpParams => {
    let params = new HttpParams();

    if (dtmName) {
        params = params.append("dtmNames", dtmName);
    }

    if (zonesFiltersData?.name) {
        params = params.append("name", zonesFiltersData.name);
    }

    if (zonesFiltersData?.infoZoneType) {
        params = params.append("localInformationTypes", zonesFiltersData.infoZoneType);
    }

    if (zonesFiltersData?.geographicalZoneType) {
        params = params.append("types", `${zonesFiltersData.geographicalZoneType}`);
    }

    if (zonesFiltersData?.zoneObjectType) {
        params = params.append("localInformationObjectTypes", `${[zonesFiltersData.zoneObjectType]}`);
    }

    if (zonesFiltersData?.dateFrom) {
        params = params.append("fromTime", new Date(zonesFiltersData.dateFrom.setHours(0, 0, 0, 0)).toISOString());
    }

    if (zonesFiltersData?.dateTo) {
        // eslint-disable-next-line no-magic-numbers
        params = params.append("toTime", new Date(zonesFiltersData.dateTo.setHours(23, 59, 59, 999)).toISOString());
    }

    if (zonesFiltersData?.timeFrom && zonesFiltersData.dateFrom) {
        const timeFrom = zonesFiltersData.timeFrom;
        const dateFrom = zonesFiltersData.dateFrom;

        params = params.set("fromTime", new Date(dateFrom.setHours(timeFrom.getHours(), timeFrom.getMinutes(), 0, 0)).toISOString());
    }

    if (zonesFiltersData?.timeTo && zonesFiltersData.dateTo) {
        const timeTo = zonesFiltersData.timeTo;
        const dateTo = zonesFiltersData.dateTo;

        params = params.set("toTime", new Date(dateTo.setHours(timeTo.getHours(), timeTo.getMinutes(), 0, 0)).toISOString());
    }

    if (zonesFiltersData?.sort) {
        params = params.set("sort", zonesFiltersData.sort);
    }

    if (authorityUnitId) {
        params = params.set("authorityUnitIds", authorityUnitId);
    }

    return params;
};

export const getZoneDynamicRequestParams = ({
    dtmName,
    defaultListSize,
    zonesFiltersData,
    authorityUnitId,
}: {
    dtmName?: DtmName;
    defaultListSize: number;
    zonesFiltersData?: ZoneFiltersData;
    authorityUnitId?: string;
}): ZoneRequestParams => {
    let params: ZoneFiltersParams = {};
    const sortCriteria: ZoneSortCriteria[] = [];

    if (zonesFiltersData?.name) {
        params = { ...params, name: zonesFiltersData.name };
    }

    if (zonesFiltersData?.infoZoneType) {
        params = { ...params, localInformationTypes: zonesFiltersData.infoZoneType };
    }

    if (zonesFiltersData?.zoneObjectType) {
        params = { ...params, localInformationObjectTypes: [zonesFiltersData.zoneObjectType] };
    }

    if (zonesFiltersData?.geographicalZoneType) {
        params = { ...params, types: zonesFiltersData.geographicalZoneType };
    }

    if (zonesFiltersData?.dateFrom) {
        params = { ...params, fromTime: new Date(zonesFiltersData.dateFrom.setHours(0, 0, 0, 0)).toISOString() };
    }

    if (zonesFiltersData?.dateTo) {
        // eslint-disable-next-line no-magic-numbers
        params = { ...params, toTime: new Date(zonesFiltersData.dateTo.setHours(23, 59, 59, 999)).toISOString() };
    }

    if (zonesFiltersData?.timeFrom && params.fromTime) {
        const timeFrom = zonesFiltersData.timeFrom;
        params = {
            ...params,
            fromTime: new Date(new Date(params.fromTime).setHours(timeFrom.getHours(), timeFrom.getMinutes(), 0, 0)).toISOString(),
        };
    }

    if (zonesFiltersData?.timeTo && params.toTime) {
        const timeTo = zonesFiltersData.timeTo;
        params = {
            ...params,
            toTime: new Date(new Date(params.toTime).setHours(timeTo.getHours(), timeTo.getMinutes(), 0, 0)).toISOString(),
        };
    }

    if (zonesFiltersData?.sort) {
        const [property, direction] = zonesFiltersData.sort.split(",");
        sortCriteria.push({ property, direction });
    }

    if (authorityUnitId) {
        params = { ...params, authorityUnitIds: [authorityUnitId] };
    }

    return {
        listRequest: {
            dtmNames: dtmName ? [dtmName] : undefined,
            ...params,
        },
        page: {
            page: 0,
            size: defaultListSize,
            sort: sortCriteria,
        },
    };
};

export interface PageableBasicBasicTemporaryZone {
    zoneList: BasicTemporaryZone[];
    pageable: Page;
}

export interface TemporaryZoneBodyResponseBody {
    notes: string;
    zone: {
        activations: {
            everydayActivations: DailyActivationData[];
            weeklyActivations: WeeklyActivationData[];
        };
        designator: string;
        type: GeographicalZone;
        descriptions: [
            {
                description: string;
                contact: string;
                languageTag: string;
            }
        ];
        area: {
            geometry: GeoJSON;
            properties: {
                type: string;
            };
        };
        minAltitude: number;
        maxAltitude: number;
        startTime: string;
        endTime: string;
        h24: boolean;
        createdAt: string;
        zoneAttributes: {
            localInformationType?: InfoZone;
            localInformationObjectType?: ZoneObjectType;
        };
    };
}

export interface ActiveZoneResponseBody {
    area: GeoJSONFeature;
    createdAt: string;
    descriptions: [
        {
            description: string;
            contact: string;
            languageTag: string;
        }
    ];
    designator: string;
    startTime: string;
    endTime: string;
    geometry: GeoJSON;
    h24: boolean;
    maxAltitude: number;
    minAltitude: number;
    type: GeographicalZone;
    zoneAttributes: {
        name: string;
        localInformationType?: InfoZone;
        localInformationObjectType?: ZoneObjectType;
    };
    activations: {
        everydayActivations: DailyActivationData[];
        weeklyActivations: WeeklyActivationData[];
    };
}

export interface AlertResponseBody {
    missionId: string;
    missionName: string;
    violationType: ViolationType;
    startedAt?: string;
    finishedAt?: string;
}

export interface BasicTemporaryZoneResponseBodyContent {
    id: string;
    type: GeographicalZone;
    designator: string;
    notes: string;
    createdAt: string;
    updatedAt: string;
    minAltitude: number;
    maxAltitude: number;
    startTime: string;
    endTime: string;
    h24: boolean;
    dtmNames: DtmName[];
    nameAttribute: string;
}

export interface MissionResponseBody {
    id: string;
    missionId: string;
    name: string;
    status: MissionStatus;
    uavName: string;
    flightStartAtMin: string;
    flightStartAtMax: string;
    flightFinishAtMin: string;
    flightFinishAtMax: string;
    flightType: MissionType;
    routeId: string;
    dtmNames: string[];
    trackersIdentifiers: string[];
    flightViolated: boolean;
    emergency: boolean;
    flightStartAt: string;
    flightFinishAt: string;
    priority: string;
    pilotPhoneNumber: string;
    pathBased: boolean;
    operatorName: string;
    plannedFlightStartAtMin: string;
    plannedFlightStartAtMax: string;
    plannedFlightFinishAtMax: string;
    plannedFlightFinishAtMin: string;
    phase: MissionProcessingPhase;
}

export const convertBasicTemporaryZoneResponseBodyToBasicTemporaryZone = (
    response: BasicTemporaryZoneResponseBodyContent[]
): BasicTemporaryZone[] =>
    response.map((draft) => ({
        id: draft.id,
        type: draft.type,
        designator: draft.nameAttribute,
        minAltitude: draft.minAltitude,
        maxAltitude: draft.maxAltitude,
        startTime: new Date(draft.startTime),
        endTime: new Date(draft.endTime),
        isH24: draft.h24,
        dtmName: draft.dtmNames[0],
    }));

export const convertZoneResponseToTemporaryZoneFormData = (zone: ActiveZoneResponseBody): TemporaryZoneFormData => {
    let dailyActivationsHours;

    if (zone.activations.everydayActivations.length) {
        const [startTimeHours, startTimeMinutes] = zone.activations.everydayActivations[0].startTime.split(":");
        const [endTimeHours, endTimeMinutes] = zone.activations.everydayActivations[0].endTime.split(":");

        dailyActivationsHours = {
            startTimeHours,
            startTimeMinutes,
            endTimeHours,
            endTimeMinutes,
        };
    }

    return {
        zoneName: zone.zoneAttributes.name,
        zoneType: zone.type,
        maxAltitude: zone.minAltitude,
        minAltitude: zone.maxAltitude,
        restriction: zone.zoneAttributes.name,
        zoneDescription: zone.descriptions[0].description,
        zoneStartDate: new Date(zone.startTime),
        zoneEndDate: new Date(zone.endTime),
        zoneStartTime: new Date(zone.startTime),
        zoneEndTime: new Date(zone.endTime),
        phoneNumber: {
            countryCode: "PL",
            number: zone.descriptions[0].contact,
        },
        note: "",
        infoZoneType: zone.zoneAttributes.localInformationType,
        zoneObjectType: zone.zoneAttributes.localInformationObjectType,
        dailyActivation:
            zone.activations.everydayActivations.length && dailyActivationsHours
                ? {
                      startTime: new Date(
                          new Date().setHours(+dailyActivationsHours.startTimeHours, +dailyActivationsHours.startTimeMinutes)
                      ),
                      endTime: new Date(new Date().setHours(+dailyActivationsHours.endTimeHours, +dailyActivationsHours.endTimeMinutes)),
                  }
                : undefined,
        weeklyActivationList: [],
        isValidityPeriod: !!(zone.activations.everydayActivations.length || zone.activations.weeklyActivations.length),
    };
};

export const convertBasicActiveZoneResponseBodyToBasicTemporaryZone = (
    response: BasicTemporaryZoneResponseBodyContent[]
): BasicTemporaryZone[] =>
    response.map((draft) => ({
        id: draft.id,
        type: draft.type,
        designator: draft.nameAttribute,
        minAltitude: draft.minAltitude,
        maxAltitude: draft.maxAltitude,
        startTime: new Date(draft.startTime),
        endTime: new Date(draft.endTime),
        isH24: draft.h24,
    }));

const convertStringToPhoneNumber = (phoneNumber: string): PhoneNumber => ({
    countryCode: "PL",
    number: phoneNumber,
});

export const convertMissionsEventHeaderBodyToMissionEventHeader = (headers: MissionMessageHeadersBody): MissionMessageHeaders => ({
    dtmNames: headers["dtm-names"],
    eventType: headers["event-type"],
    missionId: headers["mission-ids"],
    planId: headers["plan-ids"],
});

const extractHoursAndMinutes = (time: string) => {
    const [hours, minutes] = time.split(":");

    return `${hours}:${minutes}`;
};

export const convertTemporaryZoneBodyResponseToTemporaryZone = (response: TemporaryZoneBodyResponseBody): TemporaryZone => ({
    zoneName: response.zone.designator,
    zoneType: response.zone.type,
    maxAltitude: response.zone.maxAltitude,
    minAltitude: response.zone.minAltitude,
    zoneDescription: response.zone.descriptions[0].description,
    zoneStartDate: new Date(response.zone.startTime),
    zoneEndDate: new Date(response.zone.endTime),
    phoneNumber: response.zone.descriptions[0].contact
        ? {
              countryCode: "PL",
              number: response.zone.descriptions[0].contact,
          }
        : undefined,
    note: response.notes,
    isH24: response.zone.h24,
    coordinates: response.zone.area.geometry,
    createdAt: new Date(response.zone.createdAt),
    infoZoneType: response.zone.zoneAttributes.localInformationType,
    zoneObjectType: response.zone.zoneAttributes.localInformationObjectType,
    dailyActivations: response.zone.activations.everydayActivations.length
        ? {
              startTime: extractHoursAndMinutes(response.zone.activations.everydayActivations[0].startTime),
              endTime: extractHoursAndMinutes(response.zone.activations.everydayActivations[0].endTime),
          }
        : undefined,
    weeklyActivations: response.zone.activations.weeklyActivations
        .map((activation) => ({
            ...activation,
            startTime: extractHoursAndMinutes(activation.startTime),
            endTime: extractHoursAndMinutes(activation.endTime),
        }))
        .sort(
            (left: WeeklyActivationData, right: WeeklyActivationData) =>
                weekDaySortList.indexOf(left.day) - weekDaySortList.indexOf(right.day)
        ),
});

export const convertActiveZoneBodyResponseToTemporaryZone = (response: ActiveZoneResponseBody): TemporaryZone => ({
    area: response.area,
    zoneName: response.zoneAttributes.name,
    zoneType: response.type,
    maxAltitude: response.maxAltitude,
    minAltitude: response.minAltitude,
    zoneDescription: response.descriptions[0].description,
    zoneStartDate: new Date(response.startTime),
    zoneEndDate: new Date(response.endTime),
    phoneNumber: response.descriptions[0].contact
        ? {
              countryCode: "PL",
              number: response.descriptions[0].contact,
          }
        : undefined,
    isH24: response.h24,
    coordinates: response.geometry,
    createdAt: new Date(response.createdAt),
    infoZoneType: response.zoneAttributes.localInformationType,
    zoneObjectType: response.zoneAttributes.localInformationObjectType,
    dailyActivations: response.activations.everydayActivations.length
        ? {
              startTime: extractHoursAndMinutes(response.activations.everydayActivations[0].startTime),
              endTime: extractHoursAndMinutes(response.activations.everydayActivations[0].endTime),
          }
        : undefined,
    weeklyActivations: response.activations.weeklyActivations
        .map((activation) => ({
            ...activation,
            startTime: extractHoursAndMinutes(activation.startTime),
            endTime: extractHoursAndMinutes(activation.endTime),
        }))
        .sort(
            (left: WeeklyActivationData, right: WeeklyActivationData) =>
                weekDaySortList.indexOf(left.day) - weekDaySortList.indexOf(right.day)
        ),
});

const groupAlerts = (alerts: AlertResponseBody[]) =>
    alerts.reduce<{ [key: string]: AlertResponseBody[] }>(
        (hash, obj) => ({ ...hash, [obj[GROUP_ALERTS_KEY]]: [...(hash[obj[GROUP_ALERTS_KEY]] ?? []), obj] }),
        {}
    );

export const convertAlertResponseBodyToMissionAlert = (alerts: AlertResponseBody[]): MissionAlert[] => {
    const groupedAlerts = groupAlerts(alerts);

    return Object.keys(groupedAlerts).map((violation) => ({
        name: groupedAlerts[violation][0].missionName,
        missionId: groupedAlerts[violation][0].missionId,
        violations: groupedAlerts[violation].map(({ violationType, startedAt, finishedAt }) => ({
            violationType,
            startedAt: startedAt ? new Date(startedAt) : undefined,
            finishedAt: finishedAt ? new Date(finishedAt) : undefined,
        })),
    }));
};

export const convertMissionResponseBodyToProceedingMission = (mission: MissionResponseBody): ProceedingMission => ({
    id: mission.id,
    missionId: mission.missionId,
    missionType: mission.flightType,
    missionName: mission.name,
    missionStatus: mission.status,
    uavName: mission.uavName,
    routeId: mission.routeId,
    priority: mission.priority ?? DEFAULT_PRIORITY,
    flightStartAtMax: mission.flightStartAtMax ? new Date(mission.flightStartAtMax) : undefined,
    flightStartAtMin: mission.flightStartAtMin ? new Date(mission.flightStartAtMin) : undefined,
    flightFinishAtMin: mission.flightFinishAtMin ? new Date(mission.flightFinishAtMin) : undefined,
    flightFinishAtMax: mission.flightFinishAtMax ? new Date(mission.flightFinishAtMax) : undefined,
    flightStartAt: mission.flightStartAt ? new Date(mission.flightStartAt) : undefined,
    flightFinishAt: mission.flightFinishAt ? new Date(mission.flightFinishAt) : undefined,
    isViolated: mission.flightViolated,
    isEmergency: mission.emergency,
    phoneNumber: convertStringToPhoneNumber(mission.pilotPhoneNumber),
    trackerId: mission.trackersIdentifiers[0],
    isPathBased: mission.pathBased,
    operationType: OperationType.ProceedingMission,
    phase: mission.phase,
    plannedStartTime: {
        min: new Date(mission.plannedFlightStartAtMin),
        max: new Date(mission.plannedFlightStartAtMax),
    },
    plannedEndTime: {
        min: new Date(mission.plannedFlightFinishAtMin),
        max: new Date(mission.plannedFlightFinishAtMax),
    },
});

export const convertMissionResponseBodyToProceedingMissionsList = (missions: MissionResponseBody[]): ProceedingMission[] =>
    missions.map((mission) => convertMissionResponseBodyToProceedingMission(mission));

export const convertToZoneDraft = (zoneData: TemporaryZoneFormData, mapGeometry: Feature, authorityUnitId?: string) => ({
    notes: zoneData.note,
    zone: {
        type: zoneData.zoneType.toUpperCase(),
        descriptions: [
            {
                description: zoneData.zoneDescription,
                flightConditions: zoneData.restriction,
                contact: zoneData.phoneNumber?.number,
                languageTag: ZONE_LANGUAGE_TAG,
            },
        ],
        area: mapGeometry,
        geometry: mapGeometry.geometry,
        h24: !zoneData.isValidityPeriod,
        minAltitude: zoneData.minAltitudeType === AltitudeType.Ground ? 0 : +zoneData.minAltitude,
        maxAltitude: +zoneData.maxAltitude,
        startTime: zoneData.zoneStartDate.toISOString(),
        endTime: zoneData.zoneEndDate.toISOString(),
        schedule: [],
        zoneAttributes: {
            name: zoneData.zoneName,
            localInformationType: zoneData.infoZoneType,
            localInformationObjectType: zoneData.zoneObjectType,
            authorityUnitId,
        },
        activations: {
            everydayActivations:
                zoneData.dailyActivation?.startTime && zoneData?.dailyActivation.endTime
                    ? [
                          {
                              startTime: DateUtils.getISOStringTime(zoneData.dailyActivation.startTime.toISOString()),
                              endTime: DateUtils.getISOStringTime(zoneData.dailyActivation.endTime.toISOString()),
                          },
                      ]
                    : [],
            weeklyActivations: zoneData.weeklyActivationList
                ?.filter((range) => !!range.startTime && !!range.endTime)
                .reduce(
                    (result: WeeklyActivationData[], currentValue: WeeklyActivation) => [
                        ...result,
                        {
                            day: weekDayToWeekDayNameMapper.get(currentValue.rangeDate.getDay()) as WeekDay,
                            startTime: DateUtils.getISOStringTime(new Date(currentValue.startTime.setSeconds(0)).toISOString()),
                            endTime: DateUtils.getISOStringTime(new Date(currentValue.endTime.setSeconds(0)).toISOString()),
                        },
                    ],
                    []
                ),
        },
    },
});

export const convertBasicActiveZoneResponseBodyToPageableBasicTemporaryZone = (
    response: BasicTemporaryZoneResponseBody
): PageableBasicBasicTemporaryZone => ({
    zoneList: convertBasicActiveZoneResponseBodyToBasicTemporaryZone(response.content),
    pageable: {
        pageSize: response.size,
        pageNumber: response.number,
        totalElements: response.totalElements,
    },
});

export function parseWebsocketMessageBody(body: WebsocketMessage["body"]): Record<string, unknown> | FlightViolationUpdate | undefined {
    try {
        return JSON.parse(body);
    } catch {
        return;
    }
}

export function transformAddTemporaryZoneErrorResponseToTemporaryZoneError(errorResponse: HttpErrorResponse): TemporaryZoneError {
    const outsideDtmErrorMessage = "Supervisor can't create zone draft outside DTM";
    if (errorResponse.status === HttpStatusCode.BadRequest && errorResponse.error.generalMessage === outsideDtmErrorMessage) {
        return { type: TemporaryZoneErrorType.AreaOutsideDtm };
    }

    return { type: TemporaryZoneErrorType.CannotAddZone };
}
