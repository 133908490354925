import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, distinctUntilChanged, first } from "rxjs/operators";
import {
    AddingPermissionsOperatorFilterFormKeys,
    AddingPermissionsOperatorFilterParams,
} from "../../models/adding-permissions-operator.models";
import { AddingPermissionsPilotFilterFormKeys, AddingPermissionsPilotFilterParams } from "../../models/adding-permissions-pilot.models";
import {
    AddingPermissionsFilterFormKeys,
    AddingPermissionsFilterParams,
    AddingPermissionsTabType,
} from "../../models/adding-permissions.models";

interface AddingPermissionsFiltersForm {
    [AddingPermissionsFilterFormKeys.SearchPhrase]: FormControl<string>;
    [AddingPermissionsOperatorFilterFormKeys.EmergencyPermission]: FormControl<boolean>;
    [AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission]: FormControl<boolean>;
    [AddingPermissionsPilotFilterFormKeys.SuperPilot]: FormControl<boolean>;
}

interface AddingPermissionsFiltersComponentState {
    initialFilters: AddingPermissionsFilterParams | undefined;
    appliedFiltersLength: number | undefined;
    [AddingPermissionsFilterFormKeys.ActiveTabIndex]: AddingPermissionsTabType | undefined;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: AddingPermissionsFilterFormKeys.SearchPhrase,
        filterLabel: "dtmSupAddingPermissions.filters.searchPhrase",
        type: FilterType.TextEllipsis,
    },
    {
        key: AddingPermissionsOperatorFilterFormKeys.EmergencyPermission,
        filterLabel: "dtmSupAddingPermissions.permissions.permissionsLabel",
        filterValueLabel: "dtmSupAddingPermissions.permissions.emergencyLabel",
    },
    {
        key: AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission,
        filterLabel: "dtmSupAddingPermissions.permissions.permissionsLabel",
        filterValueLabel: "dtmSupAddingPermissions.permissions.hasRegulationExemptionsLabel",
    },
    {
        key: AddingPermissionsPilotFilterFormKeys.SuperPilot,
        filterLabel: "dtmSupAddingPermissions.permissions.permissionsLabel",
        filterValueLabel: "dtmSupAddingPermissions.permissions.superPilotLabel",
    },
];

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-adding-permissions-filters[activeTabIndex]",
    templateUrl: "./adding-permissions-filters.component.html",
    styleUrls: ["./adding-permissions-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AddingPermissionsFiltersComponent {
    @Input() public set activeTabIndex(value: AddingPermissionsTabType | undefined) {
        this.localStore.patchState({ activeTabIndex: value || AddingPermissionsTabType.Operators });
    }
    @Input() public set initialFilters(value: AddingPermissionsFilterParams | undefined) {
        this.localStore.patchState({ initialFilters: value });
    }

    @Output() public readonly filtersChange: EventEmitter<Partial<AddingPermissionsFilterParams>> = new EventEmitter();
    @Output() public readonly filterPermissionChange: EventEmitter<boolean> = new EventEmitter();

    protected readonly appliedFiltersLength$ = this.localStore.selectByKey("appliedFiltersLength");
    protected readonly activeTabIndex$ = this.localStore.selectByKey(AddingPermissionsFilterFormKeys.ActiveTabIndex);

    protected readonly AddingPermissionsFilterFormKeys = AddingPermissionsFilterFormKeys;
    protected readonly AddingPermissionsTabType = AddingPermissionsTabType;
    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly addingPermissionsFiltersForm = new FormGroup<AddingPermissionsFiltersForm>({
        [AddingPermissionsFilterFormKeys.SearchPhrase]: new FormControl("", { nonNullable: true }),
        [AddingPermissionsOperatorFilterFormKeys.EmergencyPermission]: new FormControl<boolean>(false, { nonNullable: true }),
        [AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission]: new FormControl<boolean>(false, { nonNullable: true }),
        [AddingPermissionsPilotFilterFormKeys.SuperPilot]: new FormControl<boolean>(false, { nonNullable: true }),
    });
    protected readonly emergencyField =
        this.addingPermissionsFiltersForm.controls[AddingPermissionsOperatorFilterFormKeys.EmergencyPermission];
    protected readonly hasRegulationExemptionsField =
        this.addingPermissionsFiltersForm.controls[AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission];
    protected readonly superpilotField = this.addingPermissionsFiltersForm.controls[AddingPermissionsPilotFilterFormKeys.SuperPilot];

    constructor(private readonly localStore: LocalComponentStore<AddingPermissionsFiltersComponentState>) {
        this.localStore.setState({
            appliedFiltersLength: 0,
            initialFilters: undefined,
            [AddingPermissionsFilterFormKeys.ActiveTabIndex]: AddingPermissionsTabType.Operators,
        });

        this.assignInitialFilters();
        this.watchFormAndEmitFiltersOnChange();
    }

    protected clearFilters() {
        this.addingPermissionsFiltersForm.reset();
    }

    private watchFormAndEmitFiltersOnChange() {
        this.addingPermissionsFiltersForm.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged(), untilDestroyed(this))
            .subscribe((filters) => {
                const activeTabIndex = this.localStore.selectSnapshotByKey(AddingPermissionsFilterFormKeys.ActiveTabIndex);
                this.setActiveFiltersCount();
                if (activeTabIndex === AddingPermissionsTabType.Operators) {
                    delete filters[AddingPermissionsPilotFilterFormKeys.SuperPilot];
                } else {
                    delete filters[AddingPermissionsOperatorFilterFormKeys.EmergencyPermission];
                    delete filters[AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission];
                }

                this.filtersChange.emit({ ...filters });
            });
    }

    private assignInitialFilters() {
        this.localStore
            .selectByKey("initialFilters")
            .pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this))
            .subscribe((filters: AddingPermissionsPilotFilterParams | AddingPermissionsOperatorFilterParams) => {
                this.addingPermissionsFiltersForm.patchValue({ ...filters });
                this.setActiveFiltersCount();

                if (AddingPermissionsPilotFilterFormKeys.SuperPilot in filters) {
                    this.superpilotField.setValue(!!filters[AddingPermissionsPilotFilterFormKeys.SuperPilot]);
                }
                if (AddingPermissionsOperatorFilterFormKeys.EmergencyPermission in filters) {
                    this.emergencyField.setValue(!!filters[AddingPermissionsOperatorFilterFormKeys.EmergencyPermission]);
                }
                if (AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission in filters) {
                    this.hasRegulationExemptionsField.setValue(
                        !!filters[AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission]
                    );
                }
            });
    }

    private setActiveFiltersCount() {
        this.localStore.patchState({
            appliedFiltersLength: Object.values(this.addingPermissionsFiltersForm.getRawValue()).flat().filter(FunctionUtils.isTruthy)
                .length,
        });
    }
}
