import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DialogService, MIN_PAGE_SIZE_VALUE, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { first, switchMap } from "rxjs/operators";
import {
    AddingPermissionsOperator,
    AddingPermissionsOperatorFilterFormKeys,
    AddingPermissionsOperatorFilterParams,
} from "../../models/adding-permissions-operator.models";
import {
    AddingPermissionsPilot,
    AddingPermissionsPilotFilterFormKeys,
    AddingPermissionsPilotFilterParams,
} from "../../models/adding-permissions-pilot.models";
import {
    AddingPermissionsFilterFormKeys,
    AddingPermissionsFilterParams,
    AddingPermissionsFiltersTabKeys,
    AddingPermissionsTabType,
    PermissionChange,
    RegulationExemption,
} from "../../models/adding-permissions.models";
import { AddingPermissionsState } from "../../state/adding-permissions-state.service";
import { AddingPermissionsActions } from "../../state/adding-permissions.actions";
import {
    RegulationExemptionDialogComponent,
    RegulationExemptionDialogData,
} from "../regulation-exemption-dialog/regulation-exemption-dialog.component";

interface AddingPermissionsContainerComponentState {
    [AddingPermissionsFilterFormKeys.ActiveTabIndex]: AddingPermissionsTabType;
    [AddingPermissionsFiltersTabKeys.OperatorsFilters]: AddingPermissionsOperatorFilterParams;
    [AddingPermissionsFiltersTabKeys.PilotsFilters]: AddingPermissionsPilotFilterParams;
}

const DEFAULT_ACTIVE_TAB = AddingPermissionsTabType.Operators;
const DEFAULT_FILTERS_VALUE = {
    [AddingPermissionsFilterFormKeys.SearchPhrase]: "",
    [PAGE_NUMBER_QUERY_PARAM]: 0,
    [PAGE_SIZE_QUERY_PARAM]: MIN_PAGE_SIZE_VALUE,
    [AddingPermissionsFilterFormKeys.ActiveTabIndex]: AddingPermissionsTabType.Operators,
};

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-adding-permissions-container",
    templateUrl: "./adding-permissions-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AddingPermissionsContainerComponent implements OnInit {
    protected readonly activeTabIndex$ = this.localStore.selectByKey(AddingPermissionsFilterFormKeys.ActiveTabIndex);
    protected readonly operatorsFilters$ = this.localStore.selectByKey(AddingPermissionsFiltersTabKeys.OperatorsFilters);
    protected readonly pilotsFilters$ = this.localStore.selectByKey(AddingPermissionsFiltersTabKeys.PilotsFilters);

    protected readonly addingPermissionsOperatorList$ = this.store.select(AddingPermissionsState.addingPermissionsOperatorsList);
    protected readonly addingPermissionsPilotList$ = this.store.select(AddingPermissionsState.addingPermissionsPilotsList);
    protected readonly addingPermissionsOperatorPages$ = this.store.select(AddingPermissionsState.addingPermissionsOperatorsPages);
    protected readonly addingPermissionsPilotPages$ = this.store.select(AddingPermissionsState.addingPermissionsPilotsPages);
    protected readonly isAddingPermissionsOperatorsListProcessing$ = this.store.select(
        AddingPermissionsState.isAddingPermissionsOperatorsListProcessing
    );
    protected readonly isAddingPermissionsPilotsListProcessing$ = this.store.select(
        AddingPermissionsState.isAddingPermissionsPilotsListProcessing
    );
    protected readonly isRegulationExemptionFormProcessing$ = this.store.select(AddingPermissionsState.isRegulationExemptionsProcessing);
    protected readonly addingPermissionsOperatorsListError$ = this.store.select(AddingPermissionsState.addingPermissionsOperatorsListError);
    protected readonly addingPermissionsPilotsListError$ = this.store.select(AddingPermissionsState.addingPermissionsPilotsListError);

    protected readonly AddingPermissionsTabType = AddingPermissionsTabType;
    protected readonly AddingPermissionsFiltersTabKeys = AddingPermissionsFiltersTabKeys;

    constructor(
        private readonly localStore: LocalComponentStore<AddingPermissionsContainerComponentState>,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService,
        private readonly dialogService: DialogService
    ) {
        const snapshotParams = this.route.snapshot.queryParams;
        const snapShotData = {
            searchPhrase: snapshotParams.searchPhrase,
            [PAGE_NUMBER_QUERY_PARAM]: snapshotParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: snapshotParams[PAGE_SIZE_QUERY_PARAM] ?? MIN_PAGE_SIZE_VALUE,
            [AddingPermissionsPilotFilterFormKeys.SuperPilot]: !!snapshotParams[AddingPermissionsPilotFilterFormKeys.SuperPilot],
            [AddingPermissionsOperatorFilterFormKeys.EmergencyPermission]:
                !!snapshotParams[AddingPermissionsOperatorFilterFormKeys.EmergencyPermission],
            [AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission]:
                !!snapshotParams[AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission],
        };
        const snapshotTabIndex: AddingPermissionsTabType = +(
            snapshotParams[AddingPermissionsFilterFormKeys.ActiveTabIndex] ?? AddingPermissionsTabType.Operators
        );

        this.localStore.setState({
            [AddingPermissionsFiltersTabKeys.OperatorsFilters]:
                snapshotTabIndex === AddingPermissionsTabType.Operators ? snapShotData : DEFAULT_FILTERS_VALUE,
            [AddingPermissionsFiltersTabKeys.PilotsFilters]:
                snapshotTabIndex === AddingPermissionsTabType.Pilots ? snapShotData : DEFAULT_FILTERS_VALUE,
            [AddingPermissionsFilterFormKeys.ActiveTabIndex]: snapshotTabIndex,
        });
    }

    public ngOnInit(): void {
        this.listenOnQueryParamsChanged();
    }

    protected updateTabIndex(activeTabIndex: AddingPermissionsTabType) {
        this.localStore.patchState({ [AddingPermissionsFilterFormKeys.ActiveTabIndex]: activeTabIndex });
        this.applyFilters(activeTabIndex);
    }

    protected navigateByParams(filters: Partial<AddingPermissionsFilterParams>, index: AddingPermissionsTabType): void {
        const activeTabIndex = this.localStore.selectSnapshotByKey(AddingPermissionsFilterFormKeys.ActiveTabIndex) ?? DEFAULT_ACTIVE_TAB;

        if (activeTabIndex !== index) {
            return;
        }

        const key = this.getFilterKeyByIndex(index);
        const previousFilterState = this.localStore.selectSnapshotByKey(key);
        this.localStore.patchState({ [key]: { ...previousFilterState, ...filters } });
        this.applyFilters(activeTabIndex);
    }

    protected changeOperatorPermission({ permission, id }: PermissionChange): void {
        this.store
            .dispatch(
                permission
                    ? new AddingPermissionsActions.SetAddingPermissionsOperatorEmergencyPermission(id)
                    : new AddingPermissionsActions.RemoveAddingPermissionsOperatorEmergencyPermission(id)
            )
            .pipe(first())
            .subscribe(() => {
                const error = this.store.selectSnapshot(AddingPermissionsState.addingPermissionsPermissionError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dtmSupAddingPermissions.toastMessages.emergencyPermissionErrorMessage")
                    );

                    return;
                }

                const successMessage = permission
                    ? "dtmSupAddingPermissions.toastMessages.setEmergencyPermissionSuccessMessage"
                    : "dtmSupAddingPermissions.toastMessages.removeEmergencyPermissionSuccessMessage";

                this.toastrService.success(this.translocoService.translate(successMessage));

                const filtersState = this.localStore.selectSnapshotByKey(AddingPermissionsFiltersTabKeys.OperatorsFilters);
                this.localStore.patchState({
                    [AddingPermissionsFiltersTabKeys.OperatorsFilters]: { ...filtersState, [PAGE_NUMBER_QUERY_PARAM]: 0 },
                });

                this.getAddingPermissionsOperatorsList();
            });
    }

    protected changePilotPermission({ permission, id }: PermissionChange): void {
        this.store
            .dispatch(
                permission
                    ? new AddingPermissionsActions.SetAddingPermissionsSuperPilotPermission(id)
                    : new AddingPermissionsActions.RemoveAddingPermissionsSuperPilotPermission(id)
            )
            .pipe(first())
            .subscribe(() => {
                const error = this.store.selectSnapshot(AddingPermissionsState.addingPermissionsPermissionError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dtmSupAddingPermissions.toastMessages.superPilotErrorMessage")
                    );

                    return;
                }

                const successMessage = permission
                    ? "dtmSupAddingPermissions.toastMessages.setSuperPilotPermissionSuccessMessage"
                    : "dtmSupAddingPermissions.toastMessages.removeSuperPilotPermissionSuccessMessage";

                this.toastrService.success(this.translocoService.translate(successMessage));

                const filtersState = this.localStore.selectSnapshotByKey(AddingPermissionsFiltersTabKeys.PilotsFilters);
                this.localStore.patchState({
                    [AddingPermissionsFiltersTabKeys.PilotsFilters]: { ...filtersState, [PAGE_NUMBER_QUERY_PARAM]: 0 },
                });

                this.getAddingPermissionsPilotList();
            });
    }

    protected getAddingPermissionsOperatorsList(shouldResetPage = false): void {
        const operatorsFilters = this.localStore.selectSnapshotByKey(AddingPermissionsFiltersTabKeys.OperatorsFilters);
        if (shouldResetPage) {
            operatorsFilters[PAGE_NUMBER_QUERY_PARAM] = 0;
        }
        this.store.dispatch(new AddingPermissionsActions.GetAddingPermissionsOperators(operatorsFilters));
    }

    protected getAddingPermissionsPilotList(shouldResetPage = false): void {
        const pilotsFilters = this.localStore.selectSnapshotByKey(AddingPermissionsFiltersTabKeys.PilotsFilters);
        if (shouldResetPage) {
            pilotsFilters[PAGE_NUMBER_QUERY_PARAM] = 0;
        }
        this.store.dispatch(new AddingPermissionsActions.GetAddingPermissionsPilots(pilotsFilters));
    }

    protected addingPermissionsPageChange({ pageIndex, pageSize }: PageEvent, filterTabKey: AddingPermissionsFiltersTabKeys): void {
        const activeTabIndex = this.localStore.selectSnapshotByKey(AddingPermissionsFilterFormKeys.ActiveTabIndex);
        const previousFilters = this.localStore.selectSnapshotByKey(filterTabKey);
        this.localStore.patchState({
            [filterTabKey]: {
                ...previousFilters,
                [PAGE_NUMBER_QUERY_PARAM]: pageIndex,
                [PAGE_SIZE_QUERY_PARAM]: pageSize,
            },
        });

        this.applyFilters(activeTabIndex);
    }

    protected getOperatorsQualifications(operator: AddingPermissionsOperator) {
        this.store
            .dispatch(new AddingPermissionsActions.GetOperatorQualifications(operator))
            .pipe(
                switchMap(() => this.store.select(AddingPermissionsState.gettingQualificationsError)),
                untilDestroyed(this)
            )
            .subscribe((isGettingOperatorQualificationsError) => {
                if (!isGettingOperatorQualificationsError) {
                    return;
                }
                this.toastrService.error(
                    this.translocoService.translate("dtmSupAddingPermissions.toastMessages.gettingOperatorQualificationsError")
                );
            });
    }

    protected getPilotQualifications(pilot: AddingPermissionsPilot) {
        this.store
            .dispatch(new AddingPermissionsActions.GetPilotQualifications(pilot))
            .pipe(
                switchMap(() => this.store.select(AddingPermissionsState.gettingQualificationsError)),
                untilDestroyed(this)
            )
            .subscribe((isGettingPilotQualificationsError) => {
                if (!isGettingPilotQualificationsError) {
                    return;
                }
                this.toastrService.error(
                    this.translocoService.translate("dtmSupAddingPermissions.toastMessages.gettingPilotQualificationsError")
                );
            });
    }

    protected resetPageNumber(value: boolean, tab: AddingPermissionsTabType) {
        if (value && tab === AddingPermissionsTabType.Operators) {
            this.getAddingPermissionsOperatorsList(true);

            return;
        }

        this.getAddingPermissionsPilotList(true);
    }

    protected openRegulationExemptionForm(permissionsOperator: AddingPermissionsOperator) {
        const dialogRef = this.dialogService.open(RegulationExemptionDialogComponent, {
            data: {
                regulationExemptions: [],
                operatorName: permissionsOperator.name,
                operatorNumber: permissionsOperator.operatorNumber,
                isProcessing$: this.isRegulationExemptionFormProcessing$,
            } satisfies RegulationExemptionDialogData,
        });

        this.setCurrentRegulationExemptions(dialogRef, permissionsOperator.id);
        this.setNewRegulationExemptions(dialogRef, permissionsOperator.id);
    }

    private listenOnQueryParamsChanged(): void {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: Params) => {
            const index = queryParams?.[AddingPermissionsFilterFormKeys.ActiveTabIndex];
            const activeTabIndex = !index ? DEFAULT_ACTIVE_TAB : +index;
            this.updateTabIndex(activeTabIndex);

            if (activeTabIndex === AddingPermissionsTabType.Operators) {
                this.getAddingPermissionsOperatorsList();

                return;
            }

            this.getAddingPermissionsPilotList();
        });
    }

    private getFilterKeyByIndex(tabIndex: AddingPermissionsTabType): AddingPermissionsFiltersTabKeys {
        switch (tabIndex) {
            case AddingPermissionsTabType.Operators:
                return AddingPermissionsFiltersTabKeys.OperatorsFilters;
            case AddingPermissionsTabType.Pilots:
                return AddingPermissionsFiltersTabKeys.PilotsFilters;
        }
    }

    private applyFilters(activeTabIndex: AddingPermissionsTabType = DEFAULT_ACTIVE_TAB): void {
        const filters = this.localStore.selectSnapshotByKey(this.getFilterKeyByIndex(+activeTabIndex));
        let params: AddingPermissionsFilterParams = {
            [AddingPermissionsFilterFormKeys.ActiveTabIndex]: activeTabIndex,
            [PAGE_NUMBER_QUERY_PARAM]: filters[PAGE_NUMBER_QUERY_PARAM],
            [PAGE_SIZE_QUERY_PARAM]: filters[PAGE_SIZE_QUERY_PARAM],
        };

        if (filters[AddingPermissionsFilterFormKeys.SearchPhrase]) {
            params = { ...params, [AddingPermissionsFilterFormKeys.SearchPhrase]: filters[AddingPermissionsFilterFormKeys.SearchPhrase] };
        }

        if (AddingPermissionsPilotFilterFormKeys.SuperPilot in filters && filters[AddingPermissionsPilotFilterFormKeys.SuperPilot]) {
            params = {
                ...params,
                [AddingPermissionsPilotFilterFormKeys.SuperPilot]: true,
            };
        }

        if (
            AddingPermissionsOperatorFilterFormKeys.EmergencyPermission in filters &&
            filters[AddingPermissionsOperatorFilterFormKeys.EmergencyPermission]
        ) {
            params = {
                ...params,
                [AddingPermissionsOperatorFilterFormKeys.EmergencyPermission]: true,
            };
        }

        if (
            AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission in filters &&
            filters[AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission]
        ) {
            params = {
                ...params,
                [AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission]: true,
            };
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    private setCurrentRegulationExemptions(dialogRef: MatDialogRef<RegulationExemptionDialogComponent>, operatorId: string) {
        dialogRef
            .afterOpened()
            .pipe(
                switchMap(() => this.store.dispatch(new AddingPermissionsActions.GetRegulationExemptions(operatorId))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(AddingPermissionsState.gettingRegulationExemptionsError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dtmSupAddingPermissions.toastMessages.gettingRegulationExemptionsError")
                    );
                    dialogRef.close();

                    return;
                }

                dialogRef.componentInstance.setRegulationExemptions(
                    this.store.selectSnapshot(AddingPermissionsState.operatorRegulationExemptions)
                );
            });
    }

    private setNewRegulationExemptions(dialogRef: MatDialogRef<RegulationExemptionDialogComponent>, operatorId: string) {
        dialogRef.componentInstance.setRegulationExemptionValue$
            .pipe(
                switchMap((regulationExemptions: RegulationExemption[]) =>
                    this.store.dispatch(new AddingPermissionsActions.SetRegulationExemptions(operatorId, regulationExemptions))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(AddingPermissionsState.settingRegulationExemptionsError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dtmSupAddingPermissions.toastMessages.settingRegulationExemptionsError")
                    );

                    return;
                }

                dialogRef.close();

                this.toastrService.success(
                    this.translocoService.translate("dtmSupAddingPermissions.toastMessages.setRegulationExemptionsSuccessMessage")
                );

                const filtersState = this.localStore.selectSnapshotByKey(AddingPermissionsFiltersTabKeys.OperatorsFilters);
                this.localStore.patchState({
                    [AddingPermissionsFiltersTabKeys.OperatorsFilters]: { ...filtersState, [PAGE_NUMBER_QUERY_PARAM]: 0 },
                });

                this.getAddingPermissionsOperatorsList();
            });
    }
}
