import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MapEntity } from "@dtm-frontend/shared/map/cesium";
import {
    AirspaceElementResponseBody,
    GEO_ZONES_ENDPOINTS,
    GeoZonesEndpoints,
    SearchAirspaceElementsRequestBody,
    convertAirspaceElementResponseBodyToAirspaceElement,
} from "@dtm-frontend/shared/map/geo-zones";
import { MissionPlanAnalysisStatus, MissionPlanDataAndCapabilities } from "@dtm-frontend/shared/mission";
import { FlightPositionUpdate, FlightPositionUpdateResponseBody, FlightPositionUpdateType } from "@dtm-frontend/shared/ui";
import {
    CheckinMessageEvent,
    CheckinsErrorType,
    DeactivationEventResponseBody,
    DeviceHistoryResponseBody,
    FlightViolationUpdate,
    MissionData,
    MissionDataResponseBody,
    convertCheckinEventBodyToCheckin,
    convertCheckinResponseBodyToCheckinList,
    getCheckinListParams,
    transformFlightPositionUpdateResponseBodyToFlightPositionUpdate,
    transformMissionDataResponse,
} from "@dtm-frontend/shared/ui/tactical";
import { Logger, RxjsUtils, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { TranslocoService } from "@jsverse/transloco";
import { BBox } from "@turf/helpers";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { DtmName } from "../../planned-missions/models/mission.models";
import {
    MissionPlanDataResponseBody,
    MissionPlanVerificationResponseBody,
    convertMissionPlanDataResponseBodyToMissionPlanDataAndCapabilities,
    transformMissionPlanVerificationResponseBodyToMissionPlanAnalysisStatus,
} from "../../shared/converters/plan-analysis.converters";
import { MissionsEvents } from "../../shared/models/shared-supervisor-client.models";
import { AuthorityUnit } from "../../sup-user";
import {
    Checkin,
    MissionAlert,
    MissionStatus,
    OperationType,
    OperationalSituationErrorType,
    OverrideMissionFormValue,
    ProceedingMission,
    TemporaryZoneErrorType,
} from "../models/operational.situation.models";
import { BasicTemporaryZone, Elevation, TemporaryZone, TemporaryZoneFormData, ZoneFiltersData } from "../models/temporary-zones.models";
import { OPERATIONAL_SITUATION_ENDPOINTS, OperationalSituationEndpoints } from "../operational-situation.tokens";
import { OperationalSituationMapService } from "./operational-situation-map.service";
import {
    ActiveZoneResponseBody,
    AlertResponseBody,
    BasicTemporaryZoneResponseBody,
    BasicTemporaryZoneResponseBodyContent,
    MissionResponseBody,
    PageableBasicBasicTemporaryZone,
    TemporaryZoneBodyResponseBody,
    convertActiveZoneBodyResponseToTemporaryZone,
    convertAlertResponseBodyToMissionAlert,
    convertBasicActiveZoneResponseBodyToBasicTemporaryZone,
    convertBasicActiveZoneResponseBodyToPageableBasicTemporaryZone,
    convertBasicTemporaryZoneResponseBodyToBasicTemporaryZone,
    convertMissionResponseBodyToProceedingMission,
    convertMissionResponseBodyToProceedingMissionsList,
    convertMissionsEventHeaderBodyToMissionEventHeader,
    convertTemporaryZoneBodyResponseToTemporaryZone,
    convertToZoneDraft,
    createTemporaryZoneParams,
    getZoneDynamicRequestParams,
    parseWebsocketMessageBody,
    transformAddTemporaryZoneErrorResponseToTemporaryZoneError,
} from "./operational-situation.converters";

export interface MissionMessageHeadersBody {
    "dtm-names": string;
    "event-type": MissionsEvents;
    "mission-ids": string;
    "plan-ids": string;
}

export interface MissionMessageHeaders {
    dtmNames: string;
    eventType: MissionsEvents;
    missionId: string;
    planId: string;
}

interface BaseMissionMessage {
    headers: MissionMessageHeaders;
}

export interface ViolationMissionMessage extends BaseMissionMessage {
    headers: MissionMessageHeaders & { eventType: ViolationEvents };
    body: FlightViolationUpdate;
}

export interface CheckinMessage extends BaseMissionMessage {
    body: Checkin;
}

type ViolationEvents = MissionsEvents.FlightViolationOccurredEvent | MissionsEvents.FlightViolationCanceledEvent;

export type MissionMessage = BaseMissionMessage | ViolationMissionMessage | CheckinMessage;

export const enum DroneTrafficEvent {
    EndFlight = "EndFlight",
    FlightConnectionLost = "FlightConnectionLost",
    StartFlight = "StartFlight",
    UpdateFlightPosition = "UpdateFlightPosition",
}

const MAXIMUM_LIST_SIZE = 2000;
const MISSION_STATUSES = [
    MissionStatus.Started,
    MissionStatus.Accepted,
    MissionStatus.Activated,
    MissionStatus.Finished,
    MissionStatus.Rejected,
];

const WATCH_EVENT_TYPES = [
    MissionsEvents.MissionActivatedEvent,
    MissionsEvents.MissionRealizationStartedEvent,
    MissionsEvents.ManualPlanVerificationSubmittedEvent,
    MissionsEvents.MissionAcceptedEvent,
    MissionsEvents.MissionEndedEvent,
    MissionsEvents.FlightViolationOccurredEvent,
    MissionsEvents.FlightViolationCanceledEvent,
    MissionsEvents.MissionCanceledEvent,
    MissionsEvents.CheckinSubmittedEvent,
    MissionsEvents.CheckinRealizationStartedEvent,
    MissionsEvents.CheckinExpiredEvent,
    MissionsEvents.CheckinCompletedEvent,
    MissionsEvents.MissionRealizationTimeoutEvent,
];

@Injectable()
export class OperationalSituationApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(OPERATIONAL_SITUATION_ENDPOINTS) private readonly endpoints: OperationalSituationEndpoints,
        @Inject(GEO_ZONES_ENDPOINTS) private readonly geoZonesEndpoints: GeoZonesEndpoints,
        private readonly websocketService: WebsocketService,
        private readonly operationalSituationMapService: OperationalSituationMapService,
        private readonly transloco: TranslocoService
    ) {}

    public startPlannedMissionsWatch(dtmName?: string, authorityUnitId?: string): Observable<MissionMessage> {
        let topicName;
        if (dtmName) {
            topicName = StringUtils.replaceInTemplate(this.endpoints.wsDtmWatchTopicName, { dtmName });
        } else if (authorityUnitId) {
            topicName = StringUtils.replaceInTemplate(this.endpoints.wsAuthorityUnitWatchTopicName, { authorityUnitId });
        } else {
            throw new Error("dtmName or authorityUnitId must be provided");
        }

        return this.websocketService.watchTopic(topicName, WATCH_EVENT_TYPES).pipe(
            map((message) => ({
                headers: convertMissionsEventHeaderBodyToMissionEventHeader(message.headers as unknown as MissionMessageHeadersBody),
                body: parseWebsocketMessageBody(message.body),
            })),
            map((message) => {
                if (message.headers.eventType === MissionsEvents.CheckinSubmittedEvent) {
                    return {
                        headers: message.headers,
                        body: {
                            ...convertCheckinEventBodyToCheckin(message.body as unknown as CheckinMessageEvent),
                            operationType: OperationType.Checkin,
                        },
                    };
                }

                return message;
            })
        );
    }

    public rejectMission(missionId: string, information: string, authorityUnitId?: string) {
        const urlParams: { [key: string]: string } = { missionId };
        if (authorityUnitId) {
            urlParams["authorityUnitId"] = authorityUnitId;
        }

        return this.httpClient
            .put(StringUtils.replaceInTemplate(this.endpoints.rejectMission, urlParams), { justification: information })
            .pipe(catchError(() => throwError(() => ({ type: OperationalSituationErrorType.CannotRejectMission }))));
    }

    public dismissAlert(missionId: string, authorityUnitId?: string): Observable<void> {
        const urlParams: { [key: string]: string } = { missionId };
        if (authorityUnitId) {
            urlParams["authorityUnitId"] = authorityUnitId;
        }

        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.dismissAlert, urlParams), {})
            .pipe(catchError(() => throwError(() => ({ type: OperationalSituationErrorType.CannotDismissAlert }))));
    }

    public getMission(missionId: string): Observable<ProceedingMission> {
        return this.httpClient
            .get<MissionResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getMission, { missionId }))
            .pipe(map((response) => convertMissionResponseBodyToProceedingMission(response)));
    }

    public overrideMissionTime(missionId: string, data: OverrideMissionFormValue, authorityUnitId?: string): Observable<void> {
        const payload = {
            startAt: data.startAt,
            finishAt: data.finishAt,
            justification: data.information,
        };

        const urlParams: { [key: string]: string } = { missionId };
        if (authorityUnitId) {
            urlParams["authorityUnitId"] = authorityUnitId;
        }

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.overrideMissionTime, urlParams), { ...payload })
            .pipe(catchError(() => throwError(() => ({ type: OperationalSituationErrorType.CannotOverrideMission }))));
    }

    public getMissionDetails(missionId: string): Observable<MissionData> {
        return this.httpClient
            .get<MissionDataResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getMissionDetails, { missionId }))
            .pipe(
                map((response) => transformMissionDataResponse(response)),
                catchError(() => throwError(() => ({ type: OperationalSituationErrorType.CannotGetMissionDetails })))
            );
    }

    public getMissions(dtmName?: string, authorityUnitId?: string): Observable<ProceedingMission[]> {
        const now = new Date();
        const yesterday = new Date(now.setDate(now.getDate() - 1)).toISOString();
        const tomorrow = new Date(now.setDate(now.getDate() + 2)).toISOString();

        let params = new HttpParams();

        params = params.set("size", MAXIMUM_LIST_SIZE);
        params = params.set("missionStatus", `${MISSION_STATUSES}`);
        params = params.set("startFrom", yesterday);
        params = params.set("endTo", tomorrow);

        if (dtmName) {
            params = params.set("dtmNames", dtmName);
        }
        if (authorityUnitId) {
            params = params.set("authorityUnits", authorityUnitId);
        }

        return this.httpClient.get<{ content: MissionResponseBody[] }>(this.endpoints.getMissions, { params }).pipe(
            map((missions) => convertMissionResponseBodyToProceedingMissionsList(missions.content)),
            catchError(() => throwError(() => ({ type: OperationalSituationErrorType.CannotGetMissionList })))
        );
    }

    public getMissionAlertList(dtmName?: string, authorityUnitId?: string): Observable<MissionAlert[]> {
        let params = new HttpParams();
        params = params.set("reviewed", false);

        if (dtmName) {
            params = params.set("dtmNames", dtmName);
        }
        if (authorityUnitId) {
            params = params.set("authorityUnitId", authorityUnitId);
        }

        return this.httpClient.get<AlertResponseBody[]>(this.endpoints.getDtmAlerts, { params }).pipe(
            map((alerts) => convertAlertResponseBodyToMissionAlert(alerts)),
            catchError(() => throwError(() => ({ type: OperationalSituationErrorType.CannotGetAlertList })))
        );
    }

    public startFlightPositionUpdatesWatch(bbox: BBox): Observable<FlightPositionUpdate> {
        return this.websocketService
            .watchTopic(StringUtils.replaceInTemplate(this.endpoints.wsFlightControlTopicName, { bbox: bbox?.join(",") ?? "" }), [
                DroneTrafficEvent.UpdateFlightPosition,
                DroneTrafficEvent.StartFlight,
                DroneTrafficEvent.EndFlight,
                DroneTrafficEvent.FlightConnectionLost,
            ])
            .pipe(
                map((message) => {
                    try {
                        const response: FlightPositionUpdateResponseBody = JSON.parse(message.body);

                        return transformFlightPositionUpdateResponseBodyToFlightPositionUpdate(
                            response,
                            this.mapEventTypeToUpdateType(message.headers["event-type"] as DroneTrafficEvent)
                        );
                    } catch (error) {
                        Logger.captureException(error);

                        return;
                    }
                }),
                RxjsUtils.filterFalsy()
            );
    }

    public getCheckins(dtmName?: string, authorityUnitId?: string): Observable<Checkin[]> {
        const now = new Date();
        const yesterday = new Date(now.setDate(now.getDate() - 1));
        const tomorrow = new Date(now.setDate(now.getDate() + 2));

        const listRequest = {
            dtmName,
            authorityUnitId,
            startFrom: yesterday,
            endTo: tomorrow,
        };

        return this.httpClient
            .post<DeviceHistoryResponseBody>(this.endpoints.getCheckins, getCheckinListParams(listRequest, MAXIMUM_LIST_SIZE))
            .pipe(
                map((response) =>
                    convertCheckinResponseBodyToCheckinList(response.content).map<Checkin>((checkin) => ({
                        ...checkin,
                        operationType: OperationType.Checkin,
                    }))
                ),
                catchError(() => throwError(() => ({ type: CheckinsErrorType.CannotGetCheckins })))
            );
    }

    private mapEventTypeToUpdateType(event: DroneTrafficEvent): FlightPositionUpdateType {
        switch (event) {
            case DroneTrafficEvent.StartFlight:
                return FlightPositionUpdateType.Start;
            case DroneTrafficEvent.EndFlight:
                return FlightPositionUpdateType.End;
            case DroneTrafficEvent.UpdateFlightPosition:
                return FlightPositionUpdateType.Update;
            case DroneTrafficEvent.FlightConnectionLost:
                return FlightPositionUpdateType.ConnectionLost;
        }
    }

    public startSectionDeactivatedEventWatch(dtmName: string): Observable<DeactivationEventResponseBody | undefined> {
        return this.websocketService
            .watchTopic(`${this.endpoints.wsDtmWatchTopicName}/${dtmName}`, [MissionsEvents.SectionDeactivated])
            .pipe(
                map((message) => {
                    try {
                        return JSON.parse(message.body);
                    } catch (error) {
                        Logger.captureException(error);

                        return;
                    }
                })
            );
    }

    public addTemporaryZone(
        zoneData: TemporaryZoneFormData,
        mapEntity: MapEntity,
        authorityUnitId: string = ""
    ): Observable<TemporaryZoneFormData> {
        const mapGeometry = this.operationalSituationMapService.convertMapEntityToFeature(mapEntity);
        if (zoneData.restriction) {
            zoneData = { ...zoneData, restriction: this.mapRestrictionToText(zoneData.restriction) };
        }
        const payload = convertToZoneDraft(zoneData, mapGeometry, authorityUnitId);

        return this.httpClient
            .post<TemporaryZoneFormData>(StringUtils.replaceInTemplate(this.endpoints.manageTemporaryZones, { authorityUnitId }), payload)
            .pipe(catchError((error) => throwError(() => transformAddTemporaryZoneErrorResponseToTemporaryZoneError(error))));
    }

    public getTemporaryZones(dtmName?: DtmName, filtersData?: ZoneFiltersData, authorityUnitId?: string): Observable<BasicTemporaryZone[]> {
        return this.httpClient
            .get<{ items: BasicTemporaryZoneResponseBodyContent[] }>(this.endpoints.getTemporaryZones, {
                params: createTemporaryZoneParams(dtmName, filtersData, authorityUnitId),
            })
            .pipe(
                map((response) => convertBasicTemporaryZoneResponseBodyToBasicTemporaryZone(response.items)),
                catchError(() => throwError(() => ({ type: TemporaryZoneErrorType.CannotGetTemporaryZones })))
            );
    }

    public finishZone(zoneId: string, authorityUnitId: string = ""): Observable<void> {
        return this.httpClient
            .put<void>(
                StringUtils.replaceInTemplate(
                    StringUtils.replaceInTemplate(this.endpoints.finishZone, {
                        authorityUnitId,
                        airspaceElementId: zoneId,
                    }),
                    { airspaceElementId: zoneId }
                ),
                {}
            )
            .pipe(catchError(() => throwError(() => ({ type: TemporaryZoneErrorType.CannotFinishZone }))));
    }

    public getDraftDetails(draftId: string): Observable<TemporaryZone> {
        return this.httpClient
            .get<TemporaryZoneBodyResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getZoneDraft, { zoneDraftId: draftId }))
            .pipe(
                map((response) => convertTemporaryZoneBodyResponseToTemporaryZone(response)),
                catchError(() => throwError(() => ({ type: TemporaryZoneErrorType.CannotGetDraftDetails })))
            );
    }

    public deleteDraft(draftId: string, authorityUnitId: string = ""): Observable<void> {
        return this.httpClient
            .delete<void>(
                StringUtils.replaceInTemplate(this.endpoints.deleteZoneDraft, {
                    authorityUnitId,
                    zoneDraftId: draftId,
                })
            )
            .pipe(catchError(() => throwError(() => ({ type: TemporaryZoneErrorType.CannotDeleteDraftZone }))));
    }

    public getElevation(mapEntity: MapEntity): Observable<Elevation> {
        const payload = this.operationalSituationMapService.convertMapEntityToFeature(mapEntity).geometry;

        return this.httpClient.post<Elevation>(this.endpoints.getElevation, { ...payload });
    }

    public publishZone(draftId: string, authorityUnitId: string = ""): Observable<void> {
        return this.httpClient
            .post<void>(
                StringUtils.replaceInTemplate(this.endpoints.publishZone, {
                    authorityUnitId,
                    zoneDraftId: draftId,
                }),
                null
            )
            .pipe(catchError(() => throwError(() => ({ type: TemporaryZoneErrorType.CannotPublishZone }))));
    }

    public getActiveZones(
        dtmName?: DtmName,
        zonesFiltersData?: ZoneFiltersData,
        authorityUnitId?: string
    ): Observable<BasicTemporaryZone[]> {
        const params = getZoneDynamicRequestParams({ dtmName, defaultListSize: MAXIMUM_LIST_SIZE, zonesFiltersData, authorityUnitId });
        params.listRequest.onlyActive = true;

        return this.httpClient
            .post<BasicTemporaryZoneResponseBody>(this.endpoints.getZones, {
                ...params,
            })
            .pipe(
                map((response) => convertBasicActiveZoneResponseBodyToBasicTemporaryZone(response.content)),
                catchError(() => throwError(() => ({ type: TemporaryZoneErrorType.CannotGetActiveZones })))
            );
    }

    public getArchiveZones(
        dtmName?: DtmName,
        pageNumber?: number,
        zonesFiltersData?: ZoneFiltersData,
        authorityUnitId?: string
    ): Observable<PageableBasicBasicTemporaryZone> {
        const params = getZoneDynamicRequestParams({ dtmName, defaultListSize: MAXIMUM_LIST_SIZE, zonesFiltersData, authorityUnitId });
        params.listRequest.onlyArchive = true;

        if (pageNumber) {
            params.page.page = pageNumber;
        }

        return this.httpClient.post<BasicTemporaryZoneResponseBody>(this.endpoints.getZones, { ...params }).pipe(
            map((response) => convertBasicActiveZoneResponseBodyToPageableBasicTemporaryZone(response)),
            catchError(() =>
                throwError(() => ({
                    type: !pageNumber ? TemporaryZoneErrorType.CannotGetArchiveZones : TemporaryZoneErrorType.CannotLoadArchivedZones,
                }))
            )
        );
    }

    public getActiveZoneDetails(zoneId: string): Observable<TemporaryZone> {
        return this.httpClient
            .get<ActiveZoneResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getZoneDetails, { airspaceElementId: zoneId }))
            .pipe(
                map((response) => convertActiveZoneBodyResponseToTemporaryZone(response)),
                catchError(() => throwError(() => ({ type: TemporaryZoneErrorType.CannotGetZoneDetails })))
            );
    }

    public getMissionPlanData(planId: string, authorityUnitId: AuthorityUnit | undefined): Observable<MissionPlanDataAndCapabilities> {
        return this.httpClient
            .get<MissionPlanDataResponseBody>(StringUtils.replaceInTemplate(this.endpoints.missionManagement, { planId }))
            .pipe(
                map((response) => convertMissionPlanDataResponseBodyToMissionPlanDataAndCapabilities(response, authorityUnitId)),
                catchError(() => throwError(() => ({ type: OperationalSituationErrorType.CannotGetMissionPlanData })))
            );
    }

    public searchAirspaceElements(options: Partial<SearchAirspaceElementsRequestBody>) {
        return this.httpClient.post<AirspaceElementResponseBody>(this.geoZonesEndpoints.searchAirspaceElements, options).pipe(
            map((response) => convertAirspaceElementResponseBodyToAirspaceElement(response, options.scope?.endTime)),
            catchError(() => throwError(() => ({ type: OperationalSituationErrorType.CannotGetAirspaceElements })))
        );
    }

    public getCurrentMissionPlanVerification(planId: string): Observable<MissionPlanAnalysisStatus> {
        return this.httpClient
            .get<MissionPlanVerificationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getMissionPlanVerification, { planId }),
                { context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true) }
            )
            .pipe(
                map((response) => transformMissionPlanVerificationResponseBodyToMissionPlanAnalysisStatus(response)),
                catchError(() => throwError(() => ({ type: OperationalSituationErrorType.Unknown })))
            );
    }

    public clonePublishedZone(zoneId: string): Observable<TemporaryZone> {
        return this.httpClient
            .get<ActiveZoneResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getZoneDetails, { airspaceElementId: zoneId }))
            .pipe(
                map((response) => convertActiveZoneBodyResponseToTemporaryZone(response)),
                catchError(() => throwError(() => ({ type: TemporaryZoneErrorType.CannotGetZoneDetails })))
            );
    }

    private mapRestrictionToText(restriction: string): string {
        return this.transloco.translate("dtmSupOperationalSituation.temporaryZonesForm.restrictionLabel", { value: restriction });
    }
}
