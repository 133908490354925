import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ADDING_PERMISSIONS_ENDPOINTS, AddingPermissionsEndpoints } from "../adding-permissions.tokens";
import {
    AddingPermissionsOperatorErrorType,
    AddingPermissionsOperatorFilterParams,
    AddingPermissionsOperatorListWithPages,
} from "../models/adding-permissions-operator.models";
import {
    AddingPermissionsPilotErrorType,
    AddingPermissionsPilotFilterParams,
    AddingPermissionsPilotListWithPages,
} from "../models/adding-permissions-pilot.models";
import { PilotOperatorQualification, RegulationExemption } from "../models/adding-permissions.models";
import {
    GetOperatorDetailsResponseBody,
    GetOperatorQualificationsResponseBody,
    GetOperatorsListResponseBody,
    GetPilotQualificationsResponseBody,
    GetPilotsListResponseBody,
    SetRegulationExemptionRequestPayload,
    convertGetOperatorDetailsResponseBodyToRegulationExemptions,
    convertGetOperatorQualificationsResponseBodyToPilotOperatorQualification,
    convertGetOperatorsListResponseBodyToOperatorsListWithPages,
    convertGetPilotQualificationsResponseBodyToPilotOperatorQualification,
    convertGetPilotsListResponseBodyToPilotsListWithPages,
    convertRegulationExemptionsToSetRegulationExemptionRequestPayload,
    getAddingPermissionsOperatorParams,
    getAddingPermissionsPilotParams,
} from "./adding-permissions.converters";

@Injectable()
export class AddingPermissionsApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(ADDING_PERMISSIONS_ENDPOINTS) private readonly endpoints: AddingPermissionsEndpoints
    ) {}

    public getOperatorsList(filters: AddingPermissionsOperatorFilterParams): Observable<AddingPermissionsOperatorListWithPages> {
        const params: HttpParams = getAddingPermissionsOperatorParams(filters);

        return this.httpClient.get<GetOperatorsListResponseBody>(this.endpoints.getOperatorsList, { params }).pipe(
            map((response) => convertGetOperatorsListResponseBodyToOperatorsListWithPages(response)),
            catchError(() => throwError(() => ({ type: AddingPermissionsOperatorErrorType.CannotGetAddingPermissionsOperatorList })))
        );
    }

    public getPilotsList(filters: AddingPermissionsPilotFilterParams): Observable<AddingPermissionsPilotListWithPages> {
        const params: HttpParams = getAddingPermissionsPilotParams(filters);

        return this.httpClient.get<GetPilotsListResponseBody>(this.endpoints.getPilotsList, { params }).pipe(
            map((response) => convertGetPilotsListResponseBodyToPilotsListWithPages(response)),
            catchError(() => throwError(() => ({ type: AddingPermissionsPilotErrorType.CannotGetAddingPermissionsPilotList })))
        );
    }

    public setAddingPermissionsSuperPilotPermission(pilotId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.setPilotPermission, { pilotId }), {})
            .pipe(
                catchError(() =>
                    throwError(() => ({ type: AddingPermissionsPilotErrorType.CannotSetAddingPermissionsSuperPilotPermission }))
                )
            );
    }

    public removeAddingPermissionsSuperPilotPermission(pilotId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removePilotPermission, { pilotId }))
            .pipe(
                catchError(() =>
                    throwError(() => ({ type: AddingPermissionsPilotErrorType.CannotRemoveAddingPermissionsSuperPilotPermission }))
                )
            );
    }

    public setAddingPermissionsEmergencyPermission(operatorId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.setOperatorPermission, { operatorId }), {})
            .pipe(
                catchError(() =>
                    throwError(() => ({ type: AddingPermissionsOperatorErrorType.CannotSetAddingPermissionsEmergencyPermission }))
                )
            );
    }

    public removeAddingPermissionsEmergencyPermission(operatorId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removeOperatorPermission, { operatorId }))
            .pipe(
                catchError(() =>
                    throwError(() => ({ type: AddingPermissionsOperatorErrorType.CannotRemoveAddingPermissionsEmergencyPermission }))
                )
            );
    }

    public getOperatorQualifications(operatorNumber: string): Observable<PilotOperatorQualification[]> {
        return this.httpClient
            .get<GetOperatorQualificationsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getOperatorPublicProfile, { operatorNumber })
            )
            .pipe(
                map((response) => convertGetOperatorQualificationsResponseBodyToPilotOperatorQualification(response)),
                catchError(() => throwError(() => ({ type: AddingPermissionsOperatorErrorType.CannotGetQualifications })))
            );
    }

    public getPilotQualifications(pilotNumber: string): Observable<PilotOperatorQualification[]> {
        return this.httpClient
            .get<GetPilotQualificationsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getPilotPublicProfile, { pilotNumber }))
            .pipe(
                map((response) => convertGetPilotQualificationsResponseBodyToPilotOperatorQualification(response)),
                catchError(() => throwError(() => ({ type: AddingPermissionsOperatorErrorType.CannotGetQualifications })))
            );
    }

    public getRegulationExemptions(operatorId: string): Observable<RegulationExemption[]> {
        return this.httpClient
            .get<GetOperatorDetailsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getOperatorDetails, { operatorId }))
            .pipe(
                map((response) => convertGetOperatorDetailsResponseBodyToRegulationExemptions(response)),
                catchError(() => throwError(() => ({ type: AddingPermissionsOperatorErrorType.CannotGetRegulationExemptions })))
            );
    }

    public setRegulationExemptions(operatorId: string, regulationExemptions: RegulationExemption[]) {
        return this.httpClient
            .put<SetRegulationExemptionRequestPayload>(
                StringUtils.replaceInTemplate(this.endpoints.setRegulationExemptions, { operatorId }),
                convertRegulationExemptionsToSetRegulationExemptionRequestPayload(regulationExemptions)
            )
            .pipe(catchError(() => throwError(() => ({ type: AddingPermissionsOperatorErrorType.CannotSetRegulationExemptions }))));
    }
}
