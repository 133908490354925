<ng-container
    *ngrxLet="{
        canEdit: canEdit$,
        missions: missions$,
        selectedMissionRoute: selectedMissionRoute$,
        currentMissionPlanData: currentMissionPlanData$,
        selectedTileId: selectedTileId$,
        shouldPilotPanelClose: shouldPilotPanelClose$,
        zones: zones$,
        isProcessing: isProcessing$,
        currentPlanAnalysisStatus: currentPlanAnalysisStatus$,
        workspacePermissions: workspacePermissions$,
        isWithdrawEnabled: isWithdrawEnabled$
    } as vm"
>
    <ng-container *ngIf="vm.missions.length; else emptyListTemplate">
        <supervisor-shared-lib-mission-info-panel
            (click)="missionSelect.emit(plannedMission)"
            [isSelected]="vm.selectedTileId === plannedMission.id"
            [isPlanRouteProcessing]="isPlanRouteProcessing$ | ngrxPush"
            [canEdit]="vm.canEdit"
            [isWithdrawEnabled]="
                vm.isWithdrawEnabled &&
                (plannedMission.phase === MissionProcessingPhase.Accepted || plannedMission.phase === MissionProcessingPhase.Waiting)
            "
            [workspacePermissions]="vm.workspacePermissions"
            (cancel)="updateMissionPhase(MissionProcessingPhase.Rejected, plannedMission.systemVerificationId)"
            (withdraw)="
                approvalWithdraw.emit({ systemVerificationId: plannedMission.systemVerificationId, missionId: plannedMission.missionId })
            "
            (save)="updateMissionPhase(MissionProcessingPhase.Accepted, plannedMission.systemVerificationId)"
            *ngFor="let plannedMission of vm.missions; trackBy: trackByMissionId"
            [mission]="plannedMission"
            (priorityEdit)="priorityEdit.emit($event)"
        >
            <dtm-mission-mission-details
                [mission]="
                    vm.currentMissionPlanData | invoke : prepareMissionDataSimple : vm.selectedMissionRoute : plannedMission.operatorName
                "
                [isExpanded]="false"
            >
                <dtm-ui-expandable-simple-header headerSlot [label]="'dtmSupPlannedMissions.phaseDescription.basicDataLabel' | transloco">
                </dtm-ui-expandable-simple-header>
            </dtm-mission-mission-details>
            <dtm-ui-expandable-panel>
                <h2 class="section-title" headerSlot>
                    {{ "dtmSupPlannedMissions.phaseDescription.routeDetailsLabel" | transloco }}
                </h2>
                <dtm-ui-route-details [route]="vm.selectedMissionRoute"></dtm-ui-route-details>
            </dtm-ui-expandable-panel>
            <dtm-mission-flight-requirements
                *ngIf="vm.currentMissionPlanData?.plan as missionPlan"
                [zones]="vm.zones"
                [areMessagesVisible]="false"
                [currentPlanAnalysisStatus]="vm.currentPlanAnalysisStatus"
                [authorityAcceptation]="missionPlan.remarks.authorityAcceptation"
                [formalJustification]="missionPlan.remarks.justification"
                [isProcessing]="vm.isProcessing"
                [missionContext]="MissionContextType.Supervisor"
                [selectedZoneId]="selectedZoneId$ | ngrxPush"
                [partialAuthorityAcceptation]="missionPlan.remarks.partialAcceptations"
                [rejectionDate]="missionPlan.mission?.rejectedAt"
                [rejectionJustification]="missionPlan.mission?.rejectionJustification"
                [shouldDisplayMessageConfirmation]="false"
                [missionPhase]="missionPlan.phase"
                [isUtmSupervisorIntegrated]="!isOldDtmSup"
                (zoneSelect)="zoneSelect.emit($event)"
            ></dtm-mission-flight-requirements>
            <supervisor-shared-lib-pilot-request
                *ngIf="plannedMission.pilotRequest"
                [mission]="plannedMission"
                (attachmentDownload)="attachmentDownload.emit($event)"
            ></supervisor-shared-lib-pilot-request>
            <supervisor-shared-lib-plan-message
                *ngIf="vm.canEdit"
                isEditable
                [panelTitle]="'dtmSupPlannedMissions.phaseDescription.header' | transloco"
                [shouldPilotPanelClose]="vm.shouldPilotPanelClose"
                [formControl]="operatorDetailsControl"
                (messagePanelOpen)="messagePanelOpen.emit()"
            ></supervisor-shared-lib-plan-message>
            <supervisor-shared-lib-plan-message
                *ngIf="vm.canEdit || plannedMission.supervisorMessage"
                [panelTitle]="'dtmSupPlannedMissions.phaseDescription.supMessageHeader' | transloco"
                [message]="plannedMission.supervisorMessage"
                [isEditable]="vm.canEdit"
                (noteUpdate)="updateNote($event, plannedMission)"
            ></supervisor-shared-lib-plan-message>
        </supervisor-shared-lib-mission-info-panel>
    </ng-container>
</ng-container>
<ng-template #emptyListTemplate>
    <dtm-ui-no-results></dtm-ui-no-results>
</ng-template>
